import React from 'react';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import {DeviceActions} from '../store/actions/Device';
import  { get } from '../store/actions/Device';
import { getDevices,getShopPagination }  from '../selectors/Devices';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IDevice,{ Device } from '../models/IDevice';
import {
  Box,
  Container,
  Button}from '@material-ui/core/';
import { TablePaginationActions } from '../components/Table/TablePaginationActions';
import { Pagination } from '../models/IPagination';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import  DeviceForm   from '../components/Device/Form';


interface DeviceListProps  {
  devices:IDevice[],
  pagination: Pagination | null,
  getDevices: (page: number | null, per_page: number | null) => void;
}

interface IState {
  current_page:number;
  per_page:  number;
  prev_page: number | null;
  next_page: number | null;
  show_form: boolean;
  editDevice: Device | null;

}

const ROW_PER_PAGE = 10;
class Devices extends React.Component<DeviceListProps,IState> {

  constructor(props:DeviceListProps){
    super(props);
    this.state={
      //la prima pagina della libreria  è 0
      current_page:0,
      per_page: ROW_PER_PAGE ,
      prev_page: null,
      next_page: null,
      show_form: false,
      editDevice: null,


    }
    this.props.getDevices(null,null);


  }




  private handleChangePage = (event: unknown, newPage: number) => {
      this.setState({current_page:newPage}, () => this.props.getDevices(this.state.current_page+1,this.state.per_page));
  };

  private handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({per_page: parseInt(event.currentTarget.value)},() =>   this.props.getDevices(this.state.current_page+1,this.state.per_page));

  };

  private handleEditButton = (event: React.MouseEvent<HTMLElement>) => {

    let device = this.props.devices.find(item => item.hash_id === event.currentTarget.id)!;
    this.setState({editDevice:device});
    this.showForm();
  }

  private showForm = () => {
    this.setState({ show_form: true });
  };



  render(){


    return(
      <Container maxWidth="lg">
        <Box>
          <DeviceForm show={this.state.show_form} device={this.state.editDevice}/>
        </Box>
        <Box className=" marginTop-20">
        <TableContainer component={Paper} className={`${this.props.devices.length  === 0 ? `hide` : `` } marginTop-20`}>
            <Table  aria-label="custom pagination table">
              <TableBody>
              {this.props.devices.map((device) => (
                <TableRow key={device.hash_id}>
                  <TableCell component="th" scope="row">
                    {device.slug}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {device.name}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton aria-label="edit" color="primary" onClick={this.handleEditButton} id={device.hash_id}>
                      <EditIcon color="primary"/>
                    </IconButton>
                   </TableCell>
                </TableRow>

              ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    colSpan={3}
                    count={this.props.pagination !== null ? this.props.pagination.total : 0}
                    rowsPerPage={this.state.per_page}
                    page={this.state.current_page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          </Box>
        </Container>
    )
  }

}
function mapStateToProps(state: AppState) {
  return {
    devices: getDevices(state),
    pagination: getShopPagination(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<DeviceActions>) {
  return {
      getDevices: (page: number | null,per_page: number | null) => get(dispatch,page,per_page),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Devices);
