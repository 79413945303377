import React from 'react';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import { LayoutActions } from '../store/actions/Layout';
import { get,resource } from '../store/actions/Layout';
import { getLayouts,getPortraitLayouts,getLandscapeLayouts,getLayoutsPagination }  from '../selectors/Layout';
import Grid from '@material-ui/core/Grid';
import LayoutCard from '../components/Layout/Card';
import ILayout from '../models/ILayout';
import Typography from '@material-ui/core/Typography';
import {
  Container,
  Box}from '@material-ui/core/';
import { Pagination } from '../models/IPagination';
import CheckIcon from '@material-ui/icons/Check';


const BORDER_DIM: number = 2;

interface LayoutListProps  {
  layouts:ILayout[],
  landscapeLayouts:ILayout[],
  portraitLayouts:ILayout[],
  pagination: Pagination | null,
  getLayouts: (page: number | null, per_page: number | null) => void;
  getResource: (url: string) => void;
  isSelectable: boolean;
  onLayouSelected: (layout: ILayout | null) => void;

}

interface IState {
  selectedLayout: ILayout | null;
}


class Layouts extends React.Component<LayoutListProps,IState> {

  constructor(props:LayoutListProps){
    super(props);
    this.props.getLayouts(null,null);
    this.state = {
      selectedLayout: null,
    }


  }


  private handleSelectLayout = (event: React.MouseEvent<HTMLElement>) => {
    if(this.props.isSelectable){
      if(event.currentTarget.id === this.state.selectedLayout?.hash_id){
        this.setState({selectedLayout:null});
        this.props.onLayouSelected(null);
      }else{
        let layout = this.props.layouts.find(item => item.hash_id === event.currentTarget.id)!;
        this.setState({selectedLayout: layout});
        this.props.onLayouSelected(layout);
      }
    }
  }



  render(){

    return(

      <Container maxWidth="lg">
        <Typography variant="h6" color="primary">
          ORIZZONTALE
        </Typography>
        <Grid container spacing={3} className="marginTop-10">
        {this.props.landscapeLayouts.map((row) => (
          <Grid key={row.slug} item xs={12} md={4}>
            <Box border={this.state.selectedLayout?.hash_id === row.hash_id ? BORDER_DIM : 0}
                  onClick={this.handleSelectLayout.bind(this)}
                  className="selected-card-box"
                  id={row.hash_id}>
              <CheckIcon className={`${this.state.selectedLayout?.hash_id === row.hash_id ? '' : 'hide'} checked-box`}/>
              <LayoutCard image={row.image} type={row.type} description={row.name}/>
            </Box>
          </Grid>
        ))}
        </Grid>
        <Typography variant="h6"  color="primary" className="marginTop-20">
          VERTICALE
        </Typography>
        <Grid container spacing={3} className="marginTop-10">
        {this.props.portraitLayouts.map((row) => (
          <Grid key={row.slug} item xs={12} md={4} >
            <Box  border={this.state.selectedLayout?.hash_id === row.hash_id ? BORDER_DIM : 0}
                  className="selected-card-box"
                  onClick={this.handleSelectLayout.bind(this)}
                  id={row.hash_id}>
              <CheckIcon className={`${this.state.selectedLayout?.hash_id === row.hash_id ? '' : 'hide'} checked-box`}/>
              <LayoutCard image={row.image} type={row.type} description={row.name}/>
            </Box>
          </Grid>
        ))}
        </Grid>
      </Container>
    )
  }

}
function mapStateToProps(state: AppState) {
  return {
    layouts: getLayouts(state),
    portraitLayouts:getPortraitLayouts(state),
    landscapeLayouts:getLandscapeLayouts(state),
    pagination: getLayoutsPagination(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<LayoutActions>) {
  return {
    getLayouts: (page: number | null,per_page: number | null) => get(dispatch,page,per_page),
    getResource: (url: string) => resource(dispatch,url),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
