
export default interface ILayout {
  readonly hash_id:     string;
  readonly name:        string;
  readonly slug:        string;
  readonly file_name:   string;
  readonly source:      string;
  readonly type:        number;
  readonly image:       string;
};


export class Layout   {
  public static readonly  TYPE      = 'layouts';
  public static readonly  LANDSCAPE = 0;
  public static readonly  PORTRAIT  = 1;

  public hash_id: string;
  public name:    string;
  public slug: string;

  constructor(){
    this.hash_id = '';
    this.name    = '';
    this.slug = '';
  }

}
