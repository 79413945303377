import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './style.css';
import  {Layout} from '../../../models/ILayout';
import Box from '@material-ui/core/Box';



interface ICard {
  image: string;
  type: number;
  description: string;
}

class LayoutCard extends React.Component<ICard> {


  render() {

    return (
      <Card >
        <CardContent className={this.props.type === Layout.LANDSCAPE ? `layout-horizontal-card` : `layout-vertical-card`}>
          <Box  display="flex"
                flexWrap={this.props.type === Layout.LANDSCAPE ? `wrap` : ``}
                alignContent="flex-start"
                p={1}
                m={1}

                >
            <Box className={this.props.type === Layout.LANDSCAPE ? `` : `vertical-max-width`}>
              <img
                src={`data:image/png;base64, ${this.props.image}`}
                className={this.props.type === Layout.LANDSCAPE ? `landscape-layout-image` : `portrait-layout-image`}/>
            </Box>
            <Box  alignSelf="flex-start">
              <Typography
                className={`${this.props.type === Layout.LANDSCAPE ? `` : ``} description`}>
                {this.props.description}
              </Typography>
            </Box>
          </Box>
        </CardContent>

      </Card>
    );
  }
}

export default LayoutCard;
