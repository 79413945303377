export enum DeviceActionTypes {
    FETCH_DEVICES          = 'FETCH_DEVICES',
    FETCH_DEVICES_SUCCESS  = 'FETCH_DEVICES_SUCCESS',
    FETCH_DEVICES_FAIL     = 'FETCH_DEVICE_FAIL',

    UPDATE_DEVICE         = 'UPDATE_SHOP',
    UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
    UPDATE_DEVICE_FAIL    = 'UPDATE_DEVICE_FAIL',





}
