
export default interface ICustomer {
  readonly hash_id: string;
};




export class Customer implements ICustomer   {
  public hash_id      = '';
  public static TYPE  = 'customers';
  constructor(){

  }

  public setHashId?(hash_id: string):void{
    this.hash_id = hash_id;
  }

  public static init(hash_id: string){
    let customer:Customer = new Customer();
    customer.hash_id      = hash_id;
    return customer;
  }



  public  static bodyObject(customer: Customer):object {
    return {
      data:{
        type:this.TYPE,
        attributes:{
          hash_id:customer.hash_id,
        },
      }
    };
  }



}
