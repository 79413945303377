import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment/';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/it';
import './style.css'

//****DEFINIZIONE DELLE PROPS DEL COMPONENTE**/
///https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-dates/react-dates-tests.tsx

const START_DATE = 'startDate';
const END_DATE   = 'endDate';

interface IDateRange {
  onDateChange?:(startDate: moment.Moment | null, endDate: moment.Moment | null) => void;

}

interface IState {
  startDate: moment.Moment | null;
  endDate:   moment.Moment | null;
  focusedInput: 'startDate' | 'endDate' | null;
}

class DateRange extends React.Component<IDateRange,IState> {
  constructor(props: IDateRange){
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput:START_DATE
    }
  }


  private handleDateChange(arg:{startDate: moment.Moment | null,endDate: moment.Moment | null}){
    this.setState({startDate:arg.startDate,endDate:arg.endDate},  () => this.resetDate());
    if(this.props.onDateChange !== undefined){
      this.props.onDateChange(arg.startDate,arg.endDate);


    }
  }


  private resetDate=() =>{
    if(this.state.startDate !== null && this.state.endDate !== null){
      this.setState({startDate:null,endDate:null});
    }
  }


  render(){
    return(

      <DateRangePicker
        startDate={this.state.startDate}
        startDateId="start_date"
        endDate={this.state.endDate}
        endDateId="end_date"
        startDatePlaceholderText="Data inizio"
        endDatePlaceholderText="Data fine"
        onDatesChange={this.handleDateChange.bind(this)}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        hideKeyboardShortcutsPanel={true}
      />

    );
  }


}


export default DateRange
