import IShop from './IShop';
import { Customer } from './ICustomer';

export default interface IDevice {
  readonly hash_id:     string;
  readonly slug:        string;
  readonly name:        string | null;
  shop:                 IShop | null;

}


export class Device  implements IDevice  {
  public static readonly  TYPE = 'devices';

  public hash_id:   string;
  public slug:      string;
  public shop:      IShop | null;
  public name:      string | null;

  constructor(){

    this.hash_id    = '';
    this.slug       = '';
    this.shop       = null;
    this.name       = '';

  }


  public  static bodyObject(device: Device,customer: Customer):object {
    return {
      data:{
        type:this.TYPE,
        hash_id:device.hash_id.length > 0 ? device.hash_id : null,
        attributes:{
          name:device.name,
        },
        relationships:{
          customer:{
            data:{
              type:Customer.TYPE,
              hash_id:customer.hash_id
            }
          }
        }
      },

    };
  }




}
