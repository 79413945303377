import {
  get as getShopFromApi,
  store as storeShopFromApi,
  update as updateShopFromApi
} from '../../services/Shop';
import { ShopActionTypes } from '../constants/Shop';
import { Dispatch } from 'redux';
import IResponseBody from '../../models/IResponseBody';
import { Pagination } from '../../models/IPagination';
import  IShop,{Shop}  from '../../models/IShop';
import  {Customer}  from '../../models/ICustomer';

/****GET SHOPS***/

interface FetchShops {
    type: ShopActionTypes.FETCH_SHOPS;
}

interface FetchShopsSuccess {
    type: ShopActionTypes.FETCH_SHOPS_SUCCESS;
    payload: IShop[];
    pagination: Pagination | null;
}

interface FetchShopsFail {
    type: ShopActionTypes.FETCH_SHOPS_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<ShopActions>,page: number | null,per_page: number | null) {
    dispatch(onGetShopsRequest());

    try {
     const response: IResponseBody = await getShopFromApi(page,per_page);

      dispatch(getShopsSuccess(response));
    } catch (err) {
      dispatch(getShopsFail(err));
    }
}


function onGetShopsRequest(): FetchShops {
  return {
  type: ShopActionTypes.FETCH_SHOPS
  };
}

function getShopsSuccess(body: IResponseBody): FetchShopsSuccess {
  let paginationProperties = new Pagination(body);
  return {
    payload:body.data.map((item) => {return <IShop> item.attributes}),
    pagination: paginationProperties,
    type: ShopActionTypes.FETCH_SHOPS_SUCCESS
  };
}

function getShopsFail(error: Error): FetchShopsFail {
  return {
    type: ShopActionTypes.FETCH_SHOPS_FAIL,
    error:error
  };
}

/****STORE SHOP***/

interface StoreShop{
  type:ShopActionTypes.STORE_SHOP
}

interface StoreShopSuccess{
  type:ShopActionTypes.STORE_SHOP_SUCCESS,
}

interface StoreShopFail{
  type:ShopActionTypes.STORE_SHOP_FAIL,
  error:Error
}


export async function store(dispatch: Dispatch<ShopActions>,shop: Shop,customer: Customer ){

  dispatch(onStoreShopRequest());

  try {
    await storeShopFromApi(shop,customer);
    dispatch(storeShopSuccess());
    get(dispatch,null,null);
  } catch (err) {
    dispatch(storeShopFail(err));
  }

}

function onStoreShopRequest(): StoreShop {
  return {
  type: ShopActionTypes.STORE_SHOP
  };
}

function storeShopSuccess(): StoreShopSuccess {
  return {
    type: ShopActionTypes.STORE_SHOP_SUCCESS
  };
}

function storeShopFail(error: Error): StoreShopFail {
  return {
    error: error,
    type: ShopActionTypes.STORE_SHOP_FAIL,
  };
}



/****UPDATE_SHOP SHOPS***/

interface UpdateShop {
    type: ShopActionTypes.UPDATE_SHOP;
}

interface UpdateShopSuccess {
    type: ShopActionTypes.UPDATE_SHOP_SUCCESS;
}

interface UpdateShopFail {
    type: ShopActionTypes.UPDATE_SHOP_FAIL;
    error: Error;
}


export async function update(dispatch: Dispatch<ShopActions>,shop: Shop,customer: Customer ){

  dispatch(onUpdateShop());

  try {
    await updateShopFromApi(shop,customer);
    dispatch(updateShopSuccess());
    get(dispatch,null,null);
  } catch (err) {
    dispatch(updateShopFail(err));
  }

}

function onUpdateShop(): UpdateShop {
  return {
  type: ShopActionTypes.UPDATE_SHOP
  };
}

function updateShopSuccess(): UpdateShopSuccess {
  console.log('aggiornamento riuscito');
  return {
    type: ShopActionTypes.UPDATE_SHOP_SUCCESS
  };
}

function updateShopFail(error: Error): UpdateShopFail {
  return {
    error: error,
    type: ShopActionTypes.UPDATE_SHOP_FAIL,
  };
}





export type ShopActions =
    | FetchShops
    | FetchShopsSuccess
    | FetchShopsFail
    | StoreShop
    | StoreShopSuccess
    | StoreShopFail
    | UpdateShop
    | UpdateShopSuccess
    | UpdateShopFail
