import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authReducer } from './reducers/Auth';
import { shopReducer } from './reducers/Shop';
import { layoutReducer } from './reducers/Layout';
import { mediaReducer } from './reducers/Media';
import { advertisingReducer } from './reducers/Advertising';
import { deviceReducer } from './reducers/Device';
import { subscriptionReducer } from './reducers/Subscription';
import { IStoreState,RootActions } from './IStoreState';

const rootReducer = combineReducers<IStoreState>({
    auth: authReducer,
    shops: shopReducer,
    layouts:layoutReducer,
    media: mediaReducer,
    advertisings:advertisingReducer,
    devices:deviceReducer,
    subscription:subscriptionReducer
});


export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(reduxThunk as ThunkMiddleware<IStoreState, RootActions>)
    )
);
