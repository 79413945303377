import Dashboard from  '../pages/Dashboard';
import Shops from  '../pages/Shops';
import Layouts from  '../pages/Layouts';
import Media from '../pages/Media';
import Plannings from '../pages/Plannings';
import Devices from '../pages/Devices';
import NewAdvertising from '../pages/NewAdvertising';
import Advertisings from '../pages/Advertisings';

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard' ,exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/shops', exact: true , name: 'Shops', component: Shops },
  { path: '/layouts', exact: true , name: 'Layout', component: Layouts },
  { path: '/media', exact:true, name:'Media', component: Media},
  { path: '/plannings', exact:true, name:'Advertising', component: Plannings},
  { path: '/advertising/new', exact:true, name:'New Advertising', component: NewAdvertising},
  { path: '/devices', exact:true, name:'Devices', component: Devices},
  { path: '/advertisings', exact:true, name:'Advertisings', component: Advertisings}
];

export default routes;
