import React from 'react';
import {
  Button,
  Container,
  Grid,
  Box
} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import "./style.css";



function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PermMediaIcon />,
    2: <DesktopWindowsIcon />,
    3: <DateRangeIcon />,
    4: <SaveIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const steps = ['Seleziona i contenuti', 'Scegli monitor e layout', 'Programma le date','Conferma e salva'];
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(255,167,38) 0%,rgb(245,124,0) 50%,rgb(255,87,34) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(255,167,38) 0%,rgb(245,124,0) 50%,rgb(255,87,34) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({

  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg,rgb(255,167,38) 0%,rgb(245,124,0) 50%,rgb(255,87,34) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg,rgb(255,167,38) 0%,rgb(245,124,0) 50%,rgb(255,87,34) 100%)',
  },
});

interface IStepper{
  onStepChange:(step:number) => void;
  canAdvance?:boolean;
  onHandleSave:() => void;

}

interface IState {
  step:number;
}

class ADVStepper extends React.Component<IStepper,IState>{

  constructor(props: IStepper){
    super(props);
    this.state = {
        step:0,
    }
  }

  private handleNext =(event: React.MouseEvent<HTMLElement>) =>{
    console.log('puoi avanzare',this.props.canAdvance);
    if(this.props.canAdvance === undefined){
      this.next();
    }else if(this.props.canAdvance){
      this.next();
    }else{
      this.stop();
    }

  }

  private next =() =>{
    this.setState({step: this.state.step +1}, () =>  this.props.onStepChange(this.state.step));
  }


  private stop =() =>{
    this.props.onStepChange(this.state.step);
  }

  private handleBack =(event: React.MouseEvent<HTMLElement>) =>{
    this.setState({step: this.state.step - 1}, () =>  this.props.onStepChange(this.state.step));

  }

  private handleSave = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onHandleSave();
  }


  render(){
    return(
    <Box>
      <Stepper alternativeLabel activeStep={this.state.step} connector={<ColorlibConnector />}>
       {steps.map((label) => (
         <Step key={label}>
           <StepLabel className="bold"  StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
         </Step>
       ))}
     </Stepper>
     <Container maxWidth="lg">
       <Grid
           container
           direction="row"
           justify="flex-end"
           alignItems="center"
           spacing={3}
         >
         <Grid item>
             <Button className={`${this.state.step === 0 ? 'hide' : ''}`} variant="contained" color="primary" onClick={this.handleBack}>Indietro</Button>
         </Grid>
         <Grid item>
             <Button className={`${this.state.step === steps.length -1 ? 'hide' : ''}`} variant="contained" color="primary" onClick={this.handleNext}>Avanti</Button>
             <Button className={`${this.state.step === steps.length -1 ? '' : 'hide'}`} variant="contained" color="primary" onClick={this.handleSave}>Salva</Button>
         </Grid>

       </Grid>
     </Container>
    </Box>

    );
  }

}

export default ADVStepper;
