import {
  get as getMediaFromApi,
  store as storeMediaFromApi
} from '../../services/Media';
import { MediaActionTypes } from '../constants/Media';
import { Dispatch } from 'redux';
import IResponseBody from '../../models/IResponseBody';
import { Pagination } from '../../models/IPagination';
import  IMedia,{Media}  from '../../models/IMedia';
import  {Customer}  from '../../models/ICustomer';


/****GET MEDIA***/

interface FetchMedia {
    type: MediaActionTypes.FETCH_MEDIA;
}

interface FetchMediaSuccess {
    type: MediaActionTypes.FETCH_MEDIA_SUCCESS;
    payload: IMedia[];
    pagination: Pagination | null;
    first:boolean;
}

interface FetchMediaFail {
    type: MediaActionTypes.FETCH_MEDIA_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<MediaActions>,page: number | null,per_page: number | null) {
    dispatch(onGetMediaRequest());

    try {
     const response: IResponseBody = await getMediaFromApi(page,per_page);

      dispatch(getMediaSuccess(response));
    } catch (err) {
      dispatch(getMediaFail(err));
    }
}


function onGetMediaRequest(): FetchMedia {
  return {
  type: MediaActionTypes.FETCH_MEDIA
  };
}

function getMediaSuccess(body: IResponseBody): FetchMediaSuccess {
  let paginationProperties = new Pagination(body);
  let firstPage = paginationProperties.current_page === 1 ? true : false;
  return {
    payload:body.data.map((item) => {return <IMedia> item.attributes}),
    pagination: paginationProperties,
    type: MediaActionTypes.FETCH_MEDIA_SUCCESS,
    first:firstPage,
  };
}

function getMediaFail(error: Error): FetchMediaFail {
  return {
    type: MediaActionTypes.FETCH_MEDIA_FAIL,
    error:error
  };
}



/***STORE MEDIA***/

interface StoreMedia {
    type: MediaActionTypes.STORE_MEDIA;
}

interface StoreMediaSuccess {
    type: MediaActionTypes.STORE_MEDIA_SUCCESS;
}

interface StoreMediaFail {
    type: MediaActionTypes.STORE_MEDIA_FAIL;
    error: Error;
}


export async function store(dispatch: Dispatch<MediaActions>,media: Media,customer: Customer ){

  dispatch(onStoreMediaRequest());

  try {
    await storeMediaFromApi(media,customer);
    dispatch(onStoreMediaSuccess());
    get(dispatch,null,null);
  } catch (err) {
    dispatch(onStoreMediaFail(err));
  }

}


function onStoreMediaRequest(): StoreMedia {
  return {
  type: MediaActionTypes.STORE_MEDIA
  };
}

function onStoreMediaSuccess(): StoreMediaSuccess {
  return {
    type: MediaActionTypes.STORE_MEDIA_SUCCESS,
  };
}

function onStoreMediaFail(error: Error): StoreMediaFail {
  return {
    type: MediaActionTypes.STORE_MEDIA_FAIL,
    error:error
  };
}



/***STORE SELECTED MEDIA ON REDUX***/

interface StoreMediaOnRedux {
    type: MediaActionTypes.STORE_MEDIA_ON_REDUX;
    payload:IMedia[],
}


export async function storeOnRedux(dispatch: Dispatch<MediaActions>,media: IMedia[] ){

  dispatch(onStoreMediaOnRedux(media));

}

function onStoreMediaOnRedux(media: IMedia[]): StoreMediaOnRedux {
  return {
    type: MediaActionTypes.STORE_MEDIA_ON_REDUX,
    payload:media
  };
}


export type MediaActions =
    | FetchMedia
    | FetchMediaSuccess
    | FetchMediaFail
    | StoreMedia
    | StoreMediaSuccess
    | StoreMediaFail
    | StoreMediaOnRedux
