export enum ShopActionTypes {
    FETCH_SHOPS           = 'FETCH_SHOPS',
    FETCH_SHOPS_SUCCESS   = 'FETCH_SHOPS_SUCCESS',
    FETCH_SHOPS_FAIL      = 'FETCH_SHOPS_FAIL',

    STORE_SHOP            = 'STORE_SHOP',
    STORE_SHOP_SUCCESS    = 'STORE_SHOP_SUCCESS',
    STORE_SHOP_FAIL       = 'STORE_SHOP_FAIL',

    UPDATE_SHOP           = 'UPDATE_SHOP',
    UPDATE_SHOP_SUCCESS   = 'UPDATE_SHOP_SUCCESS',
    UPDATE_SHOP_FAIL      = 'UPDATE_SHOP_FAIL',

}
