import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './style.css';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import {
  Box
}from '@material-ui/core';



export  const ICONS: Record<string, number> = {
  shop: 1,
  monitor: 2,
  template:3,
  planning:4
};



export  const CARD_COLOR: Record<string, string> = {
  blu_card:     'blue-card',
  green_card:   'green-card',
  orange_card:  'orange-card',
  lime_card:    'lime-card',
  indigo_card:  'indigo-card',
  deep_purple_card: 'deep-purple-card'
};

interface ICard {
  backgroundColor: string;
  content:{
    title:string;
    textColor:string;
  },
  icon?:number,
  onClick?:(event: React.MouseEvent<HTMLElement>) => void,
  index?:string

}

class DashboardCard extends React.Component<ICard> {



  render() {
    let icon = null;
    switch(this.props.icon){
      case ICONS.shop:
        icon = <Box  className={`card-icon`}><StorefrontIcon /> </Box>;
        break;
      case ICONS.monitor:
        icon = <Box  className={`card-icon`}><DesktopWindowsIcon /> </Box>;
        break;
      case ICONS.template:
        icon = <Box  className={`card-icon`}><ImportantDevicesIcon /> </Box>;
        break;
      case ICONS.planning:
        icon = <Box  className={`card-icon`}><SlideshowIcon /> </Box>;
        break;
      default:
        icon = null;
        break;
    }
    return (
      <Card className={`${this.props.backgroundColor} card-text-content c-style`} id={this.props.index} onClick={this.props.onClick}>
        <CardContent>
          {icon}
          <Typography  className={`${this.props.content.textColor}`} gutterBottom>
          {this.props.content.title}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default DashboardCard;
