export enum AdvertisingActionTypes {
    FETCH_ADVERTISING           = 'FETCH_ADVERTISING',
    FETCH_ADVERTISING_SUCCESS   = 'FETCH_ADVERTISING_SUCCESS',
    FETCH_ADVERTISING_FAIL      = 'FETCH_ADVERTISING_FAIL',

    STORE_ADVERTISING           = 'STORE_ADVERTISING',
    STORE_ADVERTISING_SUCCESS   = 'STORE_ADVERTISING_SUCCESS',
    STORE_ADVERTISING_FAIL      = 'STORE_ADVERTISING_FAIL',

}
