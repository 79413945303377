import { MediaActions } from '../actions/Media';
import { MediaActionTypes } from '../constants/Media';
import { Reducer } from 'redux';
import  IMedia  from '../../models/IMedia';
import { Pagination }  from '../../models/IPagination';

export interface MediaState {
  items: IMedia[];
  loading: boolean;
  error: Error | null;
  pagination: Pagination | null;
  saved: boolean;
  selectedItems:IMedia[];
}

const initialState = {
  items: [],
  loading: false,
  error: null,
  pagination:null,
  saved:false,
  selectedItems:[]

};

export const mediaReducer: Reducer<MediaState, MediaActions> = ( state = initialState, action) => {
  switch (action.type) {
    case MediaActionTypes.FETCH_MEDIA:
    return {
      ...state,
      loading: true
    };

    case MediaActionTypes.FETCH_MEDIA_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case MediaActionTypes.FETCH_MEDIA_SUCCESS:
    ///UTILIZZO ANCHE IL VECCHIO STATO PER L'INFINITESCROLL
    let oldState:IMedia[] = [];
    if(!action.first){
       oldState = state.items;
    }
    return {
      ...state,
      items: oldState.concat(action.payload),
      loading: false,
      saved:true,
      pagination:action.pagination
    };
    case MediaActionTypes.STORE_MEDIA:
    return {
      ...state,
      loading:true,
    }
    case MediaActionTypes.STORE_MEDIA_SUCCESS:
    return {
      ...state,
      saved:true,
      loading:false,
    }
    case MediaActionTypes.STORE_MEDIA_FAIL:
    return {
      ...state,
      saved:false,
      loading:false

    }
    case MediaActionTypes.STORE_MEDIA_ON_REDUX:
    return {
      ...state,
      selectedItems:action.payload

    }
    default:
    return state;
  }
};
