import IResponseBody from "./../models/IResponseBody";
import ApiError from "./ApiError";
import {LOCAL_STORAGE_PREFIX, API_VERSION, API_ENDPOINT,TOKEN_TYPE } from "./../utils/Config";
import { createQueryString } from './utils';
import  { Advertising }  from '../models/IAdvertising';
import  { Layout }  from '../models/ILayout';
import  { Planning }  from '../models/IPlanning';
import   Customer  from '../models/ICustomer';
import IFilters from '../models/IFilters';






export async function get(page:number | null,per_page: number | null): Promise<IResponseBody> {


  const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
  const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

  let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

  var queryString = createQueryString(page,per_page);

  const requestUrl = `${API_ENDPOINT}${API_VERSION}/advertising${queryString}`;

  const requestInit: RequestInit = {
       headers:requestHeaders,
       method: "GET"
  };

  const response = await fetch(requestUrl, requestInit);

  if (response.ok) {
    return await response.json();
  } else {

    throw new ApiError(response.status, response.statusText);
  }
}


export async function getWithPlannings(page:number | null,per_page: number | null,filters: IFilters): Promise<IResponseBody> {


  const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
  const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

  let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

  var queryString = createQueryString(page,per_page,filters);

  const requestUrl = `${API_ENDPOINT}${API_VERSION}/advertising/plannings${queryString}`;

  const requestInit: RequestInit = {
       headers:requestHeaders,
       method: "GET"
  };

  const response = await fetch(requestUrl, requestInit);

  if (response.ok) {
    return await response.json();
  } else {

    throw new ApiError(response.status, response.statusText);
  }
}


export async function store(advertising: Advertising,customer: Customer,layout:Layout,plannings:Planning[]){


    const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
    const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

    let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

    const requestUrl = `${API_ENDPOINT}${API_VERSION}/advertising`;
    const body = Advertising.bodyObject(advertising,customer,layout,plannings);
    const requestInit: RequestInit = {
      body: JSON.stringify(body),
      headers:requestHeaders,
      method: "POST"
    };

    const response = await fetch(requestUrl, requestInit);

    if (response.ok) {
      return await response.json();
    } else {
      throw new ApiError(response.status, response.statusText);
    }


}
