import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import IShop from '../models/IShop';
import {Pagination} from '../models/IPagination';


const shopsSelector = (state: AppState)       => state.shops.items;

const paginateSelector = (state: AppState)    => state.shops.pagination;

const savedSelector = (state: AppState)       => state.shops.saved;


export const getShops = createSelector(shopsSelector, (items: IShop[]) => {
    return items;
});


export const getShopPagination = createSelector(paginateSelector, (pagination: Pagination | null) => {
    return pagination;
});


export const getSaved = createSelector(savedSelector,(saved: boolean) => {
      return saved;
});
