import { ShopActions } from '../actions/Shop';
import { ShopActionTypes } from '../constants/Shop';
import { Reducer } from 'redux';
import  IShop  from '../../models/IShop';
import { Pagination }  from '../../models/IPagination';

export interface ShopState {
  items: IShop[];
  loading: boolean;
  error: Error | null;
  pagination: Pagination | null;
  saved: boolean;
}

const initialState = {
  items: [],
  loading: false,
  error: null,
  pagination:null,
  saved:false,

};

export const shopReducer: Reducer<ShopState, ShopActions> = ( state = initialState, action) => {
  switch (action.type) {
    case ShopActionTypes.FETCH_SHOPS:
    return {
      ...state,
      loading: true
    };

    case ShopActionTypes.FETCH_SHOPS_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case ShopActionTypes.FETCH_SHOPS_SUCCESS:
    return {
      ...state,
      items: action.payload,
      loading: false,
      pagination:action.pagination
    };
    case ShopActionTypes.STORE_SHOP:
    return {
      ...state,
      loading:true,
    };
    case ShopActionTypes.STORE_SHOP_SUCCESS:
    return {
      ...state,
      loading:false,
    };
    case ShopActionTypes.STORE_SHOP_FAIL:
    return {
      ...state,
      loading:false,
      error:action.error
    };
    case ShopActionTypes.UPDATE_SHOP:
    return  {
      ...state,
      loading:true,
    };
    case ShopActionTypes.UPDATE_SHOP_SUCCESS:
    console.log('testtttooo');
    return {
      ...state,
      loading:false,
      saved:true,
    };
    case ShopActionTypes.UPDATE_SHOP_FAIL:
    return {
      ...state,
      loading:false,
      saved:false,
      error:action.error
    };
    default:
    return state;
  }
};
