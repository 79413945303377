import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import IDevice from '../models/IDevice';
import {Pagination} from '../models/IPagination';


const devicesSelector   = (state: AppState)      => state.devices.items;

const paginateSelector  = (state: AppState)      => state.devices.pagination;

const byShopSelector    = (
                          state: AppState,
                          shopHashId: string)   => state.devices.items.filter((device) => {return device.shop?.hash_id === shopHashId});


export const getDevices = createSelector(devicesSelector, (items: IDevice[]) => {
    return items;
});


export const getShopPagination = createSelector(paginateSelector, (pagination: Pagination | null) => {
    return pagination;
});

export const getbyShop = createSelector(byShopSelector, (devices: IDevice[]) => {
  return devices;
});
