import {
  get as getAdvertisingFromApi,
  store as storeAdvertisingFromApi,
  getWithPlannings as getWithPlanningsFromApi
} from '../../services/Advertising';
import { AdvertisingActionTypes } from '../constants/Advertising';
import { Dispatch } from 'redux';
import IResponseBody from '../../models/IResponseBody';
import { Pagination } from '../../models/IPagination';
import  {Advertising}  from '../../models/IAdvertising';
import  {Customer}  from '../../models/ICustomer';
import  IMedia,{Media}  from '../../models/IMedia';
import  {Layout}  from '../../models/ILayout';
import  IPlanning,{ Planning } from '../../models/IPlanning';
import  IFilters  from '../../models/IFilters';

import   IAdvertising  from '../../models/IAdvertising';


/****GET ADVERTISING***/

interface FetchAdvertising {
    type: AdvertisingActionTypes.FETCH_ADVERTISING;
}

interface FetchAdvertisingSuccess {
    type: AdvertisingActionTypes.FETCH_ADVERTISING_SUCCESS;
    payload: IAdvertising[];
    pagination: Pagination | null;
}

interface FetchAdvertisingFail {
    type: AdvertisingActionTypes.FETCH_ADVERTISING_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<AdvertisingActions>,page: number | null,per_page: number | null) {
    dispatch(onGetAdvertisingRequest());

    try {
     const response: IResponseBody = await getAdvertisingFromApi(page,per_page);

      dispatch(getAdvertisingSuccess(response));
    } catch (err) {
      dispatch(getAdvertisingFail(err));
    }
}


/***GET ADVERTISING WITH PLANNINGS***/


export async function getWithPlannings(dispatch: Dispatch<AdvertisingActions>,page: number | null,per_page: number | null, filters: IFilters) {
    dispatch(onGetAdvertisingRequest());

    try {
     const response: IResponseBody = await getWithPlanningsFromApi(page,per_page,filters);

      dispatch(getAdvertisingSuccess(response));
    } catch (err) {
      dispatch(getAdvertisingFail(err));
    }
}

/************************************/


function onGetAdvertisingRequest(): FetchAdvertising {
  return {
  type: AdvertisingActionTypes.FETCH_ADVERTISING
  };
}

function getAdvertisingSuccess(body: IResponseBody): FetchAdvertisingSuccess {

  let paginationProperties = new Pagination(body);
  let payload = mapResponseToAdvertising(body);

  return {
    payload:payload,
    pagination: paginationProperties,
    type: AdvertisingActionTypes.FETCH_ADVERTISING_SUCCESS,

  };
}

function getAdvertisingFail(error: Error): FetchAdvertisingFail {
  return {
    type: AdvertisingActionTypes.FETCH_ADVERTISING_FAIL,
    error:error
  };
}




/***STORE ADVERTISING***/

interface StoreAdvertising {
    type: AdvertisingActionTypes.STORE_ADVERTISING;
}

interface StoreAdvertisingSuccess {
    type: AdvertisingActionTypes.STORE_ADVERTISING_SUCCESS;
}

interface StoreAdvertisingFail {
    type: AdvertisingActionTypes.STORE_ADVERTISING_FAIL;
    error: Error;
}


export async function store(dispatch: Dispatch<AdvertisingActions>,advertising: Advertising,customer: Customer, layout:Layout,plannings:Planning[] ){

  dispatch(onStoreAdvertisingRequest());

  try {
    await storeAdvertisingFromApi(advertising,customer,layout,plannings);
    dispatch(onStoreAdvertisingSuccess());
    get(dispatch,null,null);
  } catch (err) {
    dispatch(onStoreAdvertisingFail(err));
  }

}


function onStoreAdvertisingRequest(): StoreAdvertising {
  return {
  type: AdvertisingActionTypes.STORE_ADVERTISING
  };
}

function onStoreAdvertisingSuccess(): StoreAdvertisingSuccess {
  return {
    type: AdvertisingActionTypes.STORE_ADVERTISING_SUCCESS,
  };
}

function onStoreAdvertisingFail(error: Error): StoreAdvertisingFail {
  return {
    type: AdvertisingActionTypes.STORE_ADVERTISING_FAIL,
    error:error
  };
}



function mapResponseToAdvertising(body: IResponseBody): IAdvertising[]{



  let plannings =  body.included.reduce((filtered: IPlanning[], item) => {
    if (item.type === Planning.TYPE) {

       filtered.push(<IPlanning>item.attributes);
    }
    return filtered;
  }, []);


  let media =  body.included.reduce((filtered: IMedia[], item) => {
    if (item.type === Planning.TYPE) {

       filtered.push(<IMedia>item.attributes);
    }
    return filtered;
  }, []);



  let payload = body.data.map((item) => {
    let adv =   <IAdvertising> item.attributes;


    let relatedHashIdPlanning =  item.relationships.data.reduce((filtered: string[], item) => {
      if (item.type === Planning.TYPE) {

         filtered.push(item.hash_id);
      }
      return filtered;
    }, []);

    let relatedHashIdMedia=  item.relationships.data.reduce((filtered: string[], item) => {
      if (item.type === Media.TYPE) {

         filtered.push(item.hash_id);
      }
      return filtered;
    }, []);


    let relPlanning = plannings.filter((planning,index) => {
          return relatedHashIdPlanning.includes(planning.hash_id)
    });
    let relMedia = media.filter((currentMedia,index) => {
          return relatedHashIdMedia.includes(currentMedia.hash_id);
    });
    adv.plannings = relPlanning;
    adv.media    = relMedia;
    return adv;
  });

  return payload;

}

export type AdvertisingActions =
    | FetchAdvertising
    | FetchAdvertisingSuccess
    | FetchAdvertisingFail
    | StoreAdvertising
    | StoreAdvertisingSuccess
    | StoreAdvertisingFail
