import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import ISubscription from '../models/ISubscription';


const subscriptionSelector = (state: AppState) => state.subscription.item;


export const getSubscription = createSelector(subscriptionSelector, (subscription: ISubscription | null) => {
    return subscription;
});
