import * as React from "react";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import DashboardCard , { ICONS } from '../components/DashBoard/Card/';
import { get , SubscriptionActions} from '../store/actions/Subscription';
import ISubscription from '../models/ISubscription';
import { getSubscription } from '../selectors/Subscription';
import { getCustomer } from '../selectors/Auth';
import ICustomer from '../models/ICustomer';
import {
  Grid,
  Container,
  Box
}from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import history from '../history';


interface IDashboard {
  subscription:ISubscription | null,
  getSubscription: (page: number | null, per_page: number | null) => void;
  customer:ICustomer | null | undefined;
}

interface IState {
  shopCard:{
    title:string;
    textColor:string;
    icon?:number;
    id:string;
  },
  deviceCard:{
    title:string;
    textColor:string;
    icon?:number;
    id:string;
  }
  templateCard:{
    title:string;
    textColor:string;
    icon?:number;
    id:string;
  },
  planningCard:{
    title:string;
    textColor:string;
    icon?:number;
    id:string;
  }
}


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#558B2F', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#D50000',
  },
})(LinearProgress);


class Dashboard extends React.Component<IDashboard,IState> {
  constructor(props:IDashboard){
    super(props);
    this.state = {
        shopCard:{
          title:"NEGOZI",
          textColor:"white-textColor",
          icon:ICONS.shop,
          id:'1',
        },
        deviceCard:{
          title:"MONITOR",
          textColor:"white-textColor",
          icon:ICONS.monitor,
          id:'2',
        },
        templateCard:{
          title:"TEMPLATE",
          textColor:"white-textColor",
          icon:ICONS.template,
          id:'3',
        },
        planningCard:{
          title:"PALINSESTO",
          textColor:"white-textColor",
          icon:ICONS.planning,
          id:'4',
        }

    };

  }

  static getDerivedStateFromProps(nextProps:IDashboard, prevState:IState): IState {
    let customer = nextProps.customer;
    if(customer !== null && customer !== undefined  &&  nextProps.subscription === null){
      nextProps.getSubscription(null,null);

    }
    return prevState;
  }




  private handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    switch(event.currentTarget.id){
      case this.state.shopCard.id:
        history.push('shops');
      break;
      case this.state.deviceCard.id:
        history.push('devices');
      break;
      case this.state.templateCard.id:
        history.push('layouts');
      break;
      case this.state.planningCard.id:
        history.push('plannings');
      break;

    }
  };

  public render() {
    return (
      <Container maxWidth="md">
        <Grid container spacing={3}  justify="flex-end">
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography className="align-right" variant="h4">Spazio Libero : {this.props.subscription?.free_space_percent}%</Typography>
            </Box>
            <BorderLinearProgress
                 variant="determinate"
                 color="secondary"
                 value={this.props.subscription?.free_space_percent !== undefined ? 100 -this.props.subscription?.free_space_percent : 0}
               />
          </Grid>
        </Grid>
        <Grid container spacing={6} className="marginTop-20">
          <Grid item md={6} xs={12} >
            <DashboardCard  backgroundColor='blu-card'
                            index={this.state.shopCard.id}
                            content={this.state.shopCard}
                            icon={this.state.shopCard.icon}
                            onClick={this.handleCardClick}/>
          </Grid>
          <Grid item md={6} xs={12}>
            <DashboardCard  backgroundColor='green-card'
                            index={this.state.deviceCard.id}
                            content={this.state.deviceCard}
                            icon={this.state.deviceCard.icon}
                            onClick={this.handleCardClick}/>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <DashboardCard  backgroundColor='orange-card'
                            content={this.state.templateCard}
                            index={this.state.templateCard.id}
                            icon={this.state.templateCard.icon}
                            onClick={this.handleCardClick}/>
          </Grid>
          <Grid item md={6} xs={12}>
            <DashboardCard
                            backgroundColor='deep-purple-card'
                            content={this.state.planningCard}
                            index={this.state.planningCard.id}
                            icon={this.state.planningCard.icon}
                            onClick={this.handleCardClick}/>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    subscription: getSubscription(state),
    customer: getCustomer(state)
  };
}


function mapDispatchToProps(dispatch: Dispatch<SubscriptionActions>) {
  return {
      getSubscription: (page: number | null,per_page: number | null) => get(dispatch,page,per_page),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
