import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




interface ISnackbar {
  message: string;
  open: boolean;
}

interface IState {
  open: boolean;
}


class SuccessSnackbar extends React.Component<ISnackbar,IState>{
  constructor(props: ISnackbar){
    super(props)
    this.state = {
      open:this.props.open,
    }
  }


  private handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };



  render(){
    return (
        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {this.props.message}
          </Alert>
        </Snackbar>
    );
  }
}

export default SuccessSnackbar;
