import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Box} from '@material-ui/core';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppState } from '../../../store/IStoreState';
import { store,MediaActions } from '../../../store/actions/Media';
import { getCustomer } from '../../../selectors/Auth';
import { getLoading } from '../../../selectors/Media';
import ICustomer,{Customer} from '../../../models/ICustomer';
import { Media } from '../../../models/IMedia';
import Fab from '@material-ui/core/Fab';
import './style.css';

interface IForm {
    onChange?:(event: React.ChangeEvent<HTMLInputElement>) => void;
    customer: ICustomer | null | undefined;
    store: (media: Media,customer: Customer) => void;
    loading: boolean;
}

interface IState {
  new_media: Media | null,
}

class MediaForm extends React.Component<IForm,IState>{
  constructor(props: IForm){
    super(props);
    this.state = {
      new_media:null,
    }
  }


  private handleChange = (e: React.ChangeEvent<HTMLInputElement>):void => {
    let files = e.currentTarget.files;
    let newCustomer = Customer.init(this.props.customer!.hash_id);

    Array.from(files!).forEach(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        let newMedia = new Media();
        newMedia.name = file.name;
        newMedia.size = file.size
        newMedia.content = reader.result
        console.log(reader.result);
        let mediaType = file.type.split("/");
        switch (mediaType[0]) {
          case "image":
            newMedia.media_type = Media.IMAGE;
            break;
          case "video":
            newMedia.media_type = Media.VIDEO;
            break;
        }
      this.setState({new_media: newMedia},() => this.props.store(this.state.new_media!,newCustomer));



      };

    })

  };


  render(){
    return(
      <Box className="floating-button">

        <input
         accept="image/*,video/mp4"
         id="contained-button-file"
         multiple
         type="file"
         onChange={this.handleChange}
         className="hide"
       />
       <label htmlFor="contained-button-file">
        <CircularProgress className={`${this.props.loading ? `` : `hide`}`} />
          <Fab  component="span" color="primary" aria-label="add">
           <CloudUploadIcon />
          </Fab>
        </label>
      </Box>
    );
  }

}

function mapStateToProps(state: AppState) {
  return {
    customer: getCustomer(state),
    loading: getLoading(state)


  };
}


function mapDispatchToProps(dispatch: Dispatch<MediaActions>) {
  return {
    store: (media: Media,customer: Customer) => store(dispatch,media,customer),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(MediaForm);
