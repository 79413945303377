import {
  get as getLayoutFromAapi,
  getResource as getResourceFromApi
} from '../../services/Layout';
import { LayoutActionTypes } from '../constants/Layout';
import { Dispatch } from 'redux';
import IResponseBody from '../../models/IResponseBody';
import { Pagination } from '../../models/IPagination';
import  ILayout  from '../../models/ILayout';

/****GET LAYOUTS***/

interface FetchLayout {
    type: LayoutActionTypes.FETCH_LAYOUTS;
}

interface FetchLayoutSuccess {
    type: LayoutActionTypes.FETCH_LAYOUTS_SUCCESS;
    payload: ILayout[];
    pagination: Pagination | null;
}

interface FetchLayoutFail {
    type: LayoutActionTypes.FETCH_LAYOUTS_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<LayoutActions>,page: number | null,per_page: number | null) {
    dispatch(onGetLayoutsRequest());

    try {
     const response: IResponseBody = await getLayoutFromAapi(page,per_page);

      dispatch(getLayoutsSuccess(response));
    } catch (err) {
      dispatch(getLayoutsFails(err));
    }
}


function onGetLayoutsRequest(): FetchLayout {
  return {
  type: LayoutActionTypes.FETCH_LAYOUTS
  };
}

function getLayoutsSuccess(body: IResponseBody): FetchLayoutSuccess {
  let paginationProperties = new Pagination(body);
  return {
    payload:body.data.map((item) => {return <ILayout> item.attributes}),
    pagination: paginationProperties,
    type: LayoutActionTypes.FETCH_LAYOUTS_SUCCESS
  };
}

function getLayoutsFails(error: Error): FetchLayoutFail {
  return {
    type: LayoutActionTypes.FETCH_LAYOUTS_FAIL,
    error:error
  };
}

/****GET RESOURCE***/

interface FetchResource{
  type:LayoutActionTypes.FETCH_RESOURCE
}

interface FetchResourceSuccess{
  image: HTMLImageElement,
  type:LayoutActionTypes.FETCH_RESOURCE_SUCCESS,
}

interface FetchResourceFail{
  type:LayoutActionTypes.FETCH_RESOURCE_FAIL,
  error:Error
}


export async function resource(dispatch: Dispatch<LayoutActions>,resourceUrl: string){

  dispatch(onGetResource());

  try {
   const response : HTMLImageElement =  await getResourceFromApi(resourceUrl);

    dispatch(getResourceSuccess(response));
  } catch (err) {
    dispatch(getResourceSuccessFail(err));
  }

}

function onGetResource(): FetchResource {
  return {
    type: LayoutActionTypes.FETCH_RESOURCE
  };
}

function getResourceSuccess(image: HTMLImageElement): FetchResourceSuccess {
  return {
    image: image,
    type: LayoutActionTypes.FETCH_RESOURCE_SUCCESS
  };
}

function getResourceSuccessFail(error: Error): FetchResourceFail {
  return {
    error: error,
    type: LayoutActionTypes.FETCH_RESOURCE_FAIL,
  };
}






export type LayoutActions =
    | FetchLayout
    | FetchLayoutSuccess
    | FetchLayoutFail
    | FetchResource
    | FetchResourceSuccess
    | FetchResourceFail
