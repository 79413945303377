import React from 'react';
import { connect } from "react-redux";
import { AppState } from './store/IStoreState';
import {AuthActions} from './store/actions/Auth';
import { Dispatch } from "redux";
import  { getMe } from './store/actions/Auth';
import PersistentDrawerLeft from './components/NavBar/Drawer';
import { getAutheticated } from './selectors/Auth';
import { LOCAL_STORAGE_PREFIX } from "./utils/Config";



interface AuthListProps  {
  isAuthenticated: boolean;
  getMe: (access_token : string) => void;
}

interface IState {
  token: string | null,
}




class App extends  React.Component<AuthListProps,IState>  {

  constructor(props: AuthListProps) {
    super(props);
    var access_token: string | null;
    let params = new URLSearchParams(window.location.search);

    access_token = params.get(`access_token`);
    if(access_token === null){
      access_token = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);
    }
    this.state = {
          token: access_token,

    };
    this.props.getMe(this.state.token ?? '');

  }


  render(){

    return (
        <PersistentDrawerLeft />
    );
  }
};

function mapStateToProps(state: AppState) {
  return {
    isAuthenticated:getAutheticated(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<AuthActions>) {
  return {
      getMe: (access_token: string) => getMe(dispatch,access_token),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(App);
