import React from 'react';
import Box from '@material-ui/core/Box';
import IMedia, { Media } from '../../../models/IMedia';
import './style.css';





interface ICard {
  media:IMedia;

}



class MediaCard extends React.Component<ICard>{
  constructor(props: ICard){
    super(props);
  }



  render(){

    let content = null;

    if(this.props.media.type === Media.IMAGE){
      content  = <img  src={this.props.media.data} className="media-content"/>
    }else{
      content = <video controls className="media-content">
                  <source src={this.props.media.data} />
                </video>
    }


    return(
      <Box  key={this.props.media.hash_id} className="card-size" >
        {content}
      </Box>
    );
  }

}


export default MediaCard
