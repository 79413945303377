import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import IMedia,{Media} from '../models/IMedia';
import {Pagination} from '../models/IPagination';


const mediaSelector = (state: AppState)         => state.media.items;

const paginateSelector = (state: AppState)      => state.media.pagination;

const itemsSelector = (state: AppState)         => state.media.items;

const loadingSelector = (state: AppState)       => state.media.loading;

const selectedItemsSelector = (state: AppState) => state.media.selectedItems;



export const getMedia = createSelector(mediaSelector, (items: IMedia[]) => {
    return items;
});


export const getMediaPagination = createSelector(paginateSelector, (pagination: Pagination | null) => {
    return pagination;
});


export const getMediaImages = createSelector(itemsSelector, (items: IMedia[]) => {
    return items.filter((element, index, array) =>{
      return element.type === Media.IMAGE;
    });
});

export const getMediaVideos = createSelector(itemsSelector, (items: IMedia[]) => {
    return items.filter((element, index, array) =>{
      return element.type === Media.VIDEO;
    });
});


export const getLoading = createSelector(loadingSelector,(loading: boolean) => {
  return loading;
});


export const getMediaSelected = createSelector(selectedItemsSelector,(items: IMedia[]) =>{
return items;

});
