import IFilter from '../models/IFilters';

export function createQueryString(page: number | null,per_page:number | null,filters?:IFilter): string{

var queryFilters = "";

if(filters){
  if(filters.from !== null){
    queryFilters =  queryFilters.concat('&filter[from]='.concat(String(filters.from)));
  }

  if(filters.to !== null){
    queryFilters =  queryFilters.concat('&filter[to]='.concat(String(filters.to)));
  }

}

var query = '?page='.concat(page !== null ? String(page) : '')
.concat('&per_page=', per_page !== null ? String(per_page) : '')
.concat(queryFilters.toString());
return query;

};
