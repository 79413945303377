import * as React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import AuthenticateRoute from "./AuthenticateRoute";
import routes from "./paths";
import RedirectIfAuthenticated from "./RedirectIfAuthenticated";



interface IRoutesProps {
  readonly isAuthenticated: boolean;
}

export default function Routes(props: IRoutesProps) {
  return (
    <Switch>

      <Route exact={true} name="Login" path="/">
        {props.isAuthenticated ? <Redirect to="/dashboard" /> : ''}
      </Route>

      {routes.map((route) =>(

          <Route
          key={route.name}
          exact={route.exact}
          name={route.name}
          path={route.path}
          component={route.component}
          />

      ))}

    </Switch>
  );
}
