import IResponseBody from "./../models/IResponseBody";
import {Shop} from "./../models/IShop";
import  {Customer} from "./../models/ICustomer";
import ApiError from "./ApiError";
import {LOCAL_STORAGE_PREFIX, API_VERSION, API_ENDPOINT,TOKEN_TYPE } from "./../utils/Config";
import { createQueryString } from './utils';

const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);




export async function get(page:number | null,per_page: number | null): Promise<IResponseBody> {


  const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
  const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

  let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

  var queryString = createQueryString(page,per_page);

  const requestUrl = `${API_ENDPOINT}${API_VERSION}/shops${queryString}`;


  const requestInit: RequestInit = {
       headers:requestHeaders,
       method: "GET"
  };



  const response = await fetch(requestUrl, requestInit);

  if (response.ok) {
    return await response.json();
  } else {

    throw new ApiError(response.status, response.statusText);
  }
}

export async function store(shop: Shop,customer: Customer){


    const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
    const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

    let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

    const requestUrl = `${API_ENDPOINT}${API_VERSION}/shops`;
    const body = Shop.bodyObject(shop,customer);
    const requestInit: RequestInit = {
      body: JSON.stringify(body),
      headers:requestHeaders,
      method: "POST"
    };

    const response = await fetch(requestUrl, requestInit);

    if (response.ok) {
      return await response.json();
    } else {
      throw new ApiError(response.status, response.statusText);
    }


}


export async function update(shop: Shop,customer: Customer){


    const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
    const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

    let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};


    const requestUrl = `${API_ENDPOINT}${API_VERSION}/shops/`.concat(shop.hash_id);
    const body = Shop.bodyObject(shop,customer);
    const requestInit: RequestInit = {
      body: JSON.stringify(body),
      headers:requestHeaders,
      method: "PATCH"
    };

    const response = await fetch(requestUrl, requestInit);
    if (response.ok) {
      return  'success';
    } else {
      throw new ApiError(response.status, response.statusText);
    }

}
