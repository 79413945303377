import * as dotenv from "dotenv";

dotenv.config();
let path;
switch (process.env.NODE_ENV) {
  case "development":
    path = `./../../.env.local`;
    break;
  case "production":
    path = `./../../.env.production`;
    break;
  default:
    path = `./../../.env.default`;

}
dotenv.config({ path: path });

export const SSO_ENDPOINT           = process.env.REACT_APP_SSO_ENDPOINT;
export const API_ENDPOINT           = process.env.REACT_APP_API_ENDPOINT;
export const API_VERSION            = process.env.REACT_APP_API_VERSION;
export const TOKEN_TYPE             = process.env.REACT_APP_TOKEN_TYPE;
export const LOGIN_PAGE             = process.env.REACT_APP_LOGIN_PAGE;
export const LOCAL_STORAGE_PREFIX   = process.env.REACT_APP_LOCAL_STORAGE_PREFIX;
export const DEFAULT_DATE_FORMAT    = "DD/MM/YYYY";
