import { AuthActions } from '../actions/Auth';
import { MeActionTypes } from '../constants/Auth';
import { Reducer } from 'redux';
import  IUser  from '../../models/IUser';


export interface AuthState {
  user: IUser | null;
  loading: boolean;
  error: String | null;
  isAuthenticated: boolean;
}

const initialState = {
  user: null,
  loading: false,
  error: null,
  isAuthenticated: false
};

export const authReducer: Reducer<AuthState, AuthActions> = ( state = initialState, action) => {
  switch (action.type) {
    case MeActionTypes.FETCH_ME:
    return {
      ...state,
      loading: true
    };

    case MeActionTypes.FETCH_ME_FAIL:
    return {
      ...state,
      loading: false
    };

    case MeActionTypes.FETCH_ME_SUCCESS:
    return {
      ...state,
      user: action.payload ,
      loading: false,
      isAuthenticated:true,
    };
    default:
    return state;
  }
};
