import moment from 'moment';

export default interface IDateRange {
  startDate:    moment.Moment;
  endDate:      moment.Moment;
}

export class DateRange implements IDateRange {
    constructor (public startDate: moment.Moment, public endDate: moment.Moment) {

    }
}
