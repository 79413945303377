export enum LayoutActionTypes {
    FETCH_LAYOUTS          = 'FETCH_LAYOUT',
    FETCH_LAYOUTS_SUCCESS  = 'FETCH_LAYOUT_SUCCESS',
    FETCH_LAYOUTS_FAIL     = 'FETCH_LAYOUT_FAIL',

    FETCH_RESOURCE          = 'FETCH_RESOURCE',
    FETCH_RESOURCE_SUCCESS  = 'FETCH_RESOURCE_SUCCESS',
    FETCH_RESOURCE_FAIL     = 'FETCH_RESOURCE_FAIL',


}
