import { Customer } from "./ICustomer";
import IMedia,{ Media } from "./IMedia";
import  IPlanning,{Planning}  from "./IPlanning";
import  IRelationship  from "./IRelationship";
import  IDevice,{ Device } from "./IDevice";
import  { Layout } from "./ILayout";
import moment from 'moment';
import { DEFAULT_DATE_FORMAT} from '../utils/Config';


enum State {
    NEW         = 0,
    PROCESSING  = 1,
    READY       = 2
}


export default interface IAdvertising {
  readonly hash_id: string;
  readonly name:string;
  readonly state:State;
  readonly relationships:IRelationship | null;
  plannings:IPlanning[] | null;
  media:IMedia[] | null;
  devices:IDevice[] | null;
};


export class Advertising implements IAdvertising   {
  public static readonly  TYPE = 'advertisings';

  public hash_id:         string;
  public name:            string;
  public state:           State;
  public plannings:       IPlanning[] | null;
  public media:           IMedia[] | null;
  public devices:         IDevice[] | null;
  public relationships:   IRelationship | null;
  constructor(){

    this.hash_id        = '';
    this.name           = '';
    this.state          = State.NEW;
    this.plannings      = null;
    this.media          = null;
    this.relationships  = null;
    this.devices        = null;
  }

  public stringState():string {
      switch(this.state){
        case State.NEW:
          return 'nuovo';
        case State.PROCESSING:
          return 'in preparazione';
        case State.READY:
          return 'pronto';
        default:
          return 'nuovo'
      }
  }

  public static stringState(state: State):string {
      switch(state){
        case State.NEW:
          return 'nuovo';
        case State.PROCESSING:
          return 'in preparazione';
        case State.READY:
          return 'pronto';
        default:
          return 'nuovo'
      }
  }

  public labelState():string {
      switch(this.state){
        case State.NEW:
          return 'blue-label';
        case State.PROCESSING:
          return 'red-label';
        case State.READY:
          return 'green-label';
        default:
          return 'blue-label'
      }
  }

  public static isPlanned(adv: IAdvertising):string {
    if(adv.plannings?.length === 0){
      return 'Nessuna pianificazione';
    }

    let currentPlannig = adv.plannings!.find((item) => {
      return moment(moment().format('YYYY-MM-DD')).isBetween(item.start_at,item.end_at);
    });

    if(currentPlannig){
      return 'In corso termina il '.concat(moment(currentPlannig.end_at).format(DEFAULT_DATE_FORMAT));
    }


    let nextPlanning = adv.plannings!.filter((item) => {
       return moment(item.start_at).isAfter(moment());
    });

    if(nextPlanning.length > 0){
      let near = adv.plannings!.sort((a,b) => {
        return   moment(a.start_at) > moment(b.start_at) ? 1 : -1;
       });


      return 'Pianificato dal '
      .concat(moment(near[0].start_at).format(DEFAULT_DATE_FORMAT))
      .concat(' al ').concat(moment(near[0].end_at).format(DEFAULT_DATE_FORMAT));
    }


    // let beforPlanning = adv.plannings!.filter((item) => {
    //    return moment(item.end_at).isAfter(moment());
    // });


    let near = adv.plannings!.sort((a,b) => {
      return   moment(a.end_at) > moment(b.end_at) ? 1 : -1;
     });

    return 'Terminato il '.concat(moment(near[0].end_at).format(DEFAULT_DATE_FORMAT));
  }


  public static labelState(state: State):string {
      switch(state){
        case State.NEW:
          return 'blue-label';
        case State.PROCESSING:
          return 'red-label';
        case State.READY:
          return 'green-label';
        default:
          return 'blue-label'
      }
  }




  public  static bodyObject(advertising: Advertising,customer: Customer,layout:Layout,plannings:Planning[]):object {
    return {
      data:{
        type:this.TYPE,
        attributes:{
          name:advertising.name,
          state:State.NEW,
          plannings:{
            data: plannings.map((item,index) =>{
              return {start_at:item.formatted_start_at, end_at:item.formatted_end_at}
            })
          }

        },
        relationships:{
          customer:{
            data:{
              type:Customer.TYPE,
              hash_id:customer.hash_id
            }
          },
          media:{
            data: advertising.media?.map((item,index) =>{
              return {type:Media.TYPE , hash_id:item.hash_id}
            })
          },
          device:{
            data: advertising.devices?.map((item,index) =>{
              return {type:Device.TYPE , hash_id:item.hash_id}
            })
          },
          layout:{
            data:{
              type:Layout.TYPE,
              hash_id:layout.hash_id
            }
          },
        }
      },

    };
  }

}
