import { Customer } from "./ICustomer";

export default interface IMedia {
  readonly hash_id:     string;
  readonly name:        string;
  readonly type:        string;
  readonly source:      string;
  readonly data:        string;
};


export class Media  implements IMedia  {
  public static readonly  TYPE  = 'media';
  public static readonly  IMAGE = 'IMG';
  public static readonly  VIDEO = 'VID';

  public hash_id:     string;
  public name:        string;
  public media_type:  string;
  public content:     string | null | ArrayBuffer;
  public size:        number;
  public type:        string;
  public source:      string;
  public data:        string;

  constructor(){

    this.hash_id    = '';
    this.name       = '';
    this.media_type = '';
    this.content    = '';
    this.size       = 0;
    this.type       = '';
    this.source     = '';
    this.data       = '';

  }


  public  static bodyObject(media: Media,customer: Customer):object {
    return {
      data:{
        type:this.TYPE,
        attributes:{
          name:media.name,
          type:media.media_type,
          size:media.size,
          content:media.content
        },
        relationships:{
          customer:{
            data:{
              type:Customer.TYPE,
              hash_id:customer.hash_id
            }
          }
        }
      },

    };
  }

}
