import { SubscriptionActions } from '../actions/Subscription';
import { SubscriptionActionTypes } from '../constants/Subscription';
import { Reducer } from 'redux';
import  ISubscription  from '../../models/ISubscription';


export interface SubscriptionState {
  item: ISubscription | null;
  loading: boolean;
  error: Error | null;
}

const initialState = {
  item: null,
  loading: false,
  error: null,

};

export const subscriptionReducer: Reducer<SubscriptionState, SubscriptionActions> = ( state = initialState, action) => {
  switch (action.type) {
    case SubscriptionActionTypes.FETCH_SUBSCRIPTION:
    return {
      ...state,
      loading: true
    };

    case SubscriptionActionTypes.FETCH_SUBSCRIPTION_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case SubscriptionActionTypes.FETCH_SUBSCRIPTION_SUCCESS:
    return {
      ...state,
      item: action.payload,
      loading: false,
    };
    default:
    return state;
  }
};
