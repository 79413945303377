import React from 'react';
import {
  Box,
  Grid,
  Container,
  Select
} from '@material-ui/core';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import  ADVStepper   from '../components/Advertising/Stepper';
import  Media  from './Media';
import IMedia from '../models/IMedia';
import IDevice from '../models/IDevice';
import ICustomer,{Customer} from '../models/ICustomer';
import IShop from '../models/IShop';
import IDateRange,{DateRange as DateRangeObject} from '../models/IDateRange';
import { getMediaSelected }  from '../selectors/Media';
import { getDevices } from '../selectors/Devices';
import { getShops }  from '../selectors/Shop';
import { getCustomer }  from '../selectors/Auth';
import { getError,getSaved }  from '../selectors/Advertising';
import { get as getShopsFromApi } from '../store/actions/Shop';
import { get as getDevicesFromApi} from '../store/actions/Device';
import { store  } from '../store/actions/Advertising';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Layouts from './Layouts';
import { RootActions } from  '../store/IStoreState';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from '../components/Dialogs/AlertDialog';
import ILayout,{Layout}     from '../models/ILayout';
import { Advertising }   from '../models/IAdvertising';
import DateRange   from '../components/DateRange';
import moment from 'moment';
import  DateRangeItem   from '../components/Advertising/Date';
import Typography from '@material-ui/core/Typography';
import MediaCard from '../components/Media/Card';
import LayoutCard from '../components/Layout/Card';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import {Planning} from '../models/IPlanning';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import history from '../history';


function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MEDIA_STEP          = 0;
const DISPLAY_STEP        = 1;
const DATE_RANGE_STEP     = 2;
const SAVE_STEP           = 3;
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm"


interface ChipData {
  key: string;
  label: string;
}

interface INewAdvertising {
  mediaSelected: IMedia[];
  getShops: (page: number | null, per_page: number | null) => void;
  getDevices: (page: number | null, per_page: number | null) => void;
  shops:IShop[];
  devices:IDevice[];
  customer: ICustomer | null | undefined;
  errorOnSave:Error | null;
  saved:boolean;
  store:(advertising: Advertising,customer:Customer,layout:Layout,plannings:Planning[]) => void;


}

interface IState {
  step:number;
  selectedShop:string;
  selectedDevice:string[];
  filterdDevices:IDevice[];
  selectedMedia: IMedia[];
  selectedLayout: ILayout | null;
  selectedPlannings:IDateRange[];
  canAdvance:boolean;
  advertisingName:string;
  dialog:{
    show:boolean;
    message:string;
  },
  snackbar:{
    show:boolean;
    message:string;
  }
}

class NewAdvertising extends React.Component<INewAdvertising,IState>{
  constructor(props: INewAdvertising){
    super(props);
    this.props.getShops(null,1000);
    this.props.getDevices(null,1000);
    this.state = {
      step:0,
      selectedShop:'',
      selectedDevice:[],
      filterdDevices:[],
      selectedMedia:[],
      selectedLayout:null,
      selectedPlannings:[],
      canAdvance:false,
      advertisingName:'',
      dialog:{
        show:false,
        message:'',
      },
      snackbar:{
        show:true,
        message:'pubblicità salvata'
      }

    }
  }



  private handleStepChange = (step: number) => {

    //QUANDO NAVIGO INDIETRO
    if(step < this.state.step ){
      this.setState({ step: step});
      this.setState({canAdvance:true});
      return;
    }

    ////QUANDO NAVIGO AVANTI
    if(step < 2){
      this.setState({canAdvance:false});
    }else{
      this.setState({canAdvance:true});
    }

    let dialog = this.state.dialog;
    if(this.state.step === MEDIA_STEP && this.state.selectedMedia.length === MEDIA_STEP){
      dialog.show     = true;
      dialog.message  = 'Seleziona almeno un immagine o un video!';

    }else if(this.state.step === DISPLAY_STEP && ( this.state.selectedDevice.length === MEDIA_STEP || this.state.selectedLayout === null)){
      console.log(step);
      dialog.show     = true;
      dialog.message  = 'Seleziona almeno un Dispositivo e un layout';
      this.setState({dialog:dialog});
    }
    else{
      console.log('step ',step);
      this.setState({step: step});
    }
      this.setState({dialog:dialog});
  }



  private handleShopChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let hash_id = event.target.value as string;
    let devices = this.props.devices.filter((device) =>{
      return device.shop?.hash_id === hash_id;
    });
    let device = devices.length > 0 ? devices[0].hash_id : '';
    //this.setState({selectedShop:hash_id,filterdDevices:devices,selectedDevice:device},() => this.checkStepMonitor());
    this.setState({selectedShop:hash_id,filterdDevices:devices});
  }

  private handleDeviceChange = (event: React.ChangeEvent<{value: unknown}>) => {
    let hash_id   = event.target.value as string[];
    this.setState({selectedDevice:hash_id},() => this.checkStepMonitor());
  }

  private handleRemoveDevice = (chipToDelete: ChipData) => () => {
    let selectedDevice = this.state.selectedDevice;
    let index =   selectedDevice.indexOf(chipToDelete.key);
    selectedDevice.splice(index,1);
    this.setState({selectedDevice:selectedDevice});
  }


  private handleMediaSelected = (media: IMedia) => {
    let selectedMedia = this.state.selectedMedia;
    let canAdvance    = false;
    if(selectedMedia.indexOf(media) > -1){
      let index =   selectedMedia.indexOf(media);
      selectedMedia.splice(index,1);
    }else{
      selectedMedia.push(media);
    }
    canAdvance = selectedMedia.length > 0 ? true : false;
    this.setState({selectedMedia:selectedMedia,canAdvance:canAdvance});


  }

  private handleCloseDialog = (): void => {
    let dialog      = this.state.dialog;
    dialog.show     = false;
    this.setState({dialog: dialog});
  }

  private handleSelectLayout = (layout: ILayout | null) => {
    this.setState({selectedLayout: layout},() => this.checkStepMonitor());
  }


  private checkStepMonitor(){
    if(this.state.selectedLayout !== null && this.state.selectedDevice.length > 0){
      console.log('advance montitor');
      this.setState({canAdvance:true});
    }
  }

  private handleDateChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>{
    if(startDate !== null && endDate !== null){
      let selected = new DateRangeObject(startDate!,endDate!)
      let selectedPlannings = this.state.selectedPlannings;
      selectedPlannings.push(selected);
      this.setState({selectedPlannings:selectedPlannings});
    }
  }

  private handleRemoveDate = (index: number) => {
    let selectedPlannings = this.state.selectedPlannings;
    selectedPlannings.splice(index,1);
    this.setState({selectedPlannings: selectedPlannings});
  }

  private handleCloseSnackbar  = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbar      = this.state.snackbar;
    snackbar.show     = false;
    this.setState({snackbar:snackbar});
    history.push('/dashboard');
  }


  private handleSave = () => {
    if(this.state.advertisingName.length === 0 ){
      let dialog = this.state.dialog;
      dialog.show     = true;
      dialog.message  = 'Inserisci un nome alla tua pubblicità';
      this.setState({dialog:dialog});
    }else{
      let advertising       = new Advertising();
      advertising.name      = this.state.advertisingName;
      let devices           = this.props.devices.filter((item) =>{
        return this.state.selectedDevice.includes(item.hash_id);
      });
      advertising.devices   = devices;
      advertising.media     = this.state.selectedMedia;
      let plannings         = this.state.selectedPlannings.map((item) => {
        let planning        =  new Planning();
        planning.formatted_start_at   = item.startDate.format(DEFAULT_DATE_FORMAT);
        planning.formatted_end_at     = item.endDate.format(DEFAULT_DATE_FORMAT);
        return planning;
      });
      let customer = Customer.init(this.props.customer!.hash_id);
      this.props.store(advertising,customer,this.state.selectedLayout!,plannings);


    }



  }

  private handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.currentTarget.value;
    this.setState({advertisingName:name});
    console.log('adv name',name);

  }



  render(){

    return(

      <Box>
        {
          this.props.errorOnSave !== null

          ?
          <AlertDialog show={true} message={this.props.errorOnSave.message}  onClose={this.handleCloseDialog}/>
          :

          ''
        }
        <AlertDialog show={this.state.dialog.show} message={this.state.dialog.message}  onClose={this.handleCloseDialog}/>
        <Container maxWidth="lg">
          <ADVStepper onStepChange={this.handleStepChange} canAdvance={this.state.canAdvance} onHandleSave={this.handleSave}/>
        </Container>
        <Box className={`${this.state.step == MEDIA_STEP ? `` : `hide` } marginTop-20`}>
          <Media  onMediaSelected={this.handleMediaSelected}/>
        </Box>
        <Box className={`${this.state.step == DISPLAY_STEP ? `` : `hide` } marginTop-40`}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputLabel id="shop-label" htmlFor="shop-select">Negozio</InputLabel>
                  <Select
                    labelId="shop-label"
                    value={this.state.selectedShop}
                    onChange={this.handleShopChange}>
                    {this.props.shops.map((shop) =>(
                      <MenuItem key={shop.hash_id} value={shop.hash_id}>{shop.name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="device-select">Dispositivo</InputLabel>
                  <Select
                      labelId="device-select"
                      id="device-chip"
                      multiple
                      value={this.state.selectedDevice}
                      onChange={this.handleDeviceChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div >
                        {this.props.devices.map((item) => {
                          return this.state.selectedDevice.includes(item.hash_id) ?
                              <Chip key={item.hash_id} label={item.slug} color="primary" />
                           :  ''
                        })}
                        </div>
                      )}

                    >
                      {this.state.filterdDevices.map((row) => (
                        <MenuItem key={row.hash_id} value={row.hash_id}>
                          {row.slug}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
          <Box className="marginTop-20">
            <Layouts isSelectable={true} onLayouSelected={this.handleSelectLayout}/>
          </Box>
        </Box>
        <Box className={`${this.state.step == DATE_RANGE_STEP ? `` : `hide` } marginTop-40`}>
          <Container maxWidth="lg">
          <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              >
              <Grid item>
                <DateRange onDateChange={this.handleDateChange}/>
              </Grid>
          </Grid>
          <Box className=" marginTop-20">
            <Grid container spacing={3}>
              {this.state.selectedPlannings.map((row,index) => (
                <Grid item  key={index}>
                  <DateRangeItem key={index} startDate={row.startDate} endDate={row.endDate} id={index} onDeleteClik={this.handleRemoveDate}/>
                </Grid>
              ))}
            </Grid>
          </Box>
          </Container>
        </Box>
        <Box  className={`${this.state.step == SAVE_STEP ? `` : `hide` } marginTop-20`}>
          <Container maxWidth="lg">

          <Typography variant="h6" color="primary">
            Assegna un nome all tuo contenuto e salva!
          </Typography>
            <TextField required id="standard-required" label="obbligatorio" defaultValue="" fullWidth onChange={this.handleChangeName}/>

            <Typography variant="h6" color="primary">
              Contenuti
            </Typography>
            <Grid container spacing={3} className="marginTop-20">
              {this.state.selectedMedia.map((row,index) => (
                <Grid key={index} item xs={12} md={3}>
                  <MediaCard media={row}  />
                </Grid>
              ))}
            </Grid>
            <Typography variant="h6" color="primary">
              Monitor e Layout
            </Typography>
            <Typography>
            {this.props.devices.map((item) => {
              return this.state.selectedDevice.includes(item.hash_id) ?
                  item.slug
               :  ''
            })}
            </Typography>
              {this.state.selectedLayout !== null

                ?   <Box className="summary-layout-card"><LayoutCard  image={this.state.selectedLayout!.image} type={this.state.selectedLayout!.type} description={this.state.selectedLayout!.name}/></Box>

                : ''


              }
              <Typography variant="h6" color="primary">
                PianificazioneIPlanning
              </Typography>
              <Grid container spacing={3}>
                {this.state.selectedPlannings.map((row,index) => (
                  <Grid item xs={12} md={2} key={index}>
                    <DateRangeItem key={index} startDate={row.startDate} endDate={row.endDate} id={index}/>
                  </Grid>
                ))}
              </Grid>
          </Container>
        </Box>
        {

          this.props.saved === true

          ?

          <Snackbar open={this.state.snackbar.show} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
             <Alert onClose={this.handleCloseSnackbar} severity="success">
               {this.state.snackbar.message}
             </Alert>
          </Snackbar>

          :

          ''

        }
      </Box>

    );
  }

}

function mapStateToProps(state: AppState) {
  return {
    mediaSelected:getMediaSelected(state),
    shops:getShops(state),
    devices:getDevices(state),
    errorOnSave:getError(state),
    customer: getCustomer(state),
    saved:getSaved(state),
  };
}



function mapDispatchToProps(dispatch: Dispatch<RootActions>) {
  return {
    getShops: (page: number | null,per_page: number | null)    => getShopsFromApi(dispatch,page,per_page),
    getDevices: (page: number | null,per_page: number | null)  => getDevicesFromApi(dispatch,page,per_page),
    store:(advertising: Advertising,customer:Customer,layout:Layout,plannings:Planning[]) => store(dispatch,advertising,customer,layout,plannings)

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(NewAdvertising);
