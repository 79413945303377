import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import IAdvertising from '../models/IAdvertising';
import {Pagination} from '../models/IPagination';


const advertisingsSelector = (state: AppState)      => state.advertisings.items;

const paginateSelector    = (state: AppState)       => state.advertisings.pagination;

const loadingSelector     = (state: AppState)       => state.advertisings.loading;

const errorSelector       = (state: AppState)       => state.advertisings.error;

const savedSelector      = (state: AppState)        => state.advertisings.saved;


export const getAdvertisings = createSelector(advertisingsSelector, (items: IAdvertising[]) => {
    return items;
});


export const getAdvertisingsPagination = createSelector(paginateSelector, (pagination: Pagination | null) => {
    return pagination;
});



export const getLoading = createSelector(loadingSelector,(loading: boolean) => {
  return loading;
});


export const getError = createSelector(errorSelector,(error: Error | null)  => {
  return error;
});

export const getSaved = createSelector(savedSelector,(saved: boolean)  => {
  return saved;
});
