import IUser from "./../models/IUser";
import ApiError from "./ApiError";
import { API_ENDPOINT } from "./../utils/Config";
import { TOKEN_TYPE , LOGIN_PAGE ,LOCAL_STORAGE_PREFIX,API_VERSION } from "./../utils/Config";

export async function me(access_token: string): Promise<IUser> {
  const requestUrl = `${API_ENDPOINT}${API_VERSION}/me`;
  const headers = new Headers();
  if(access_token !== null){
    //headers.delete("Authorization");

    headers.append("Authorization", `${TOKEN_TYPE} ${access_token}`);
  }

  const requestInit: RequestInit = {
       headers,
       method: "GET"
  };

  const response = await fetch(requestUrl, requestInit);

  if (response.ok) {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}access_token`,  access_token);
    const body =  await response.json();
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}user`,  body.attributes.hash_id);
    //history.push("/dashboard");
    return body;
  } else {
  //  logout();
    throw new ApiError(response.status, response.statusText);
  }
}

export function logout():void {
  localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}access_token`);
  window.location.href = `${LOGIN_PAGE}`;
}

// export async function updateSite(site: ISite): Promise<ISite> {
//   const requestUrl = `${baseUrl}sites/${site.id}`;
//
//   const headers = new Headers();
//   headers.append("Content-Type", "application/json");
//
//   const requestInit: RequestInit = {
//     body: JSON.stringify(stripProperties(site)),
//     headers,
//     method: "PATCH"
//   };
//
//   // Throws TypeError for network error. See for details: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
//   const response = await fetch(requestUrl, requestInit);
//
//   if (response.ok) {
//     return await response.json();
//   } else {
//     throw new ApiError(response.status, response.statusText);
//   }
// }
//
// export async function deleteSite(siteId: string): Promise<void> {
//   const requestUrl = `${baseUrl}sites/${siteId}`;
//
//   const requestInit: RequestInit = {
//     method: "DELETE"
//   };
//
//   // Throws TypeError for network error. See for details: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
//   const response = await fetch(requestUrl, requestInit);
//
//   if (!response.ok) {
//     throw new ApiError(response.status, response.statusText);
//   }
// }
//
// export async function addSite(site: ISite): Promise<ISite> {
//   const requestUrl = `${baseUrl}sites/`;
//
//   const headers = new Headers();
//   headers.append("Content-Type", "application/json");
//
//   const requestInit: RequestInit = {
//     body: JSON.stringify(stripProperties(site)),
//     headers,
//     method: "POST"
//   };
//
//   // Throws TypeError for network error. See for details: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
//   const response = await fetch(requestUrl, requestInit);
//
//   if (response.ok) {
//     return await response.json();
//   } else {
//     throw new ApiError(response.status, response.statusText);
//   }
// }
//
// // Using this to remove any other properties that happen to be on object so only
// // send ISite properties to server.
// function stripProperties({ id, name, url, password, userName }: ISite): ISite {
//   return {
//     id,
//     name,
//     password,
//     url,
//     userName
//   };
// }
