import React from 'react';
import {Device} from '../../../models/IDevice';
import Grid from '@material-ui/core/Grid';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Box,
  TextField}from '@material-ui/core/';
import { AppState } from '../../../store/IStoreState';
import { update,DeviceActions } from '../../../store/actions/Device';
import  AlertDialog  from '../../../components/Dialogs/AlertDialog';
import { getCustomer } from '../../../selectors/Auth';
import ICustomer,{Customer} from '../../../models/ICustomer';
import { getSaved }  from '../../../selectors/Shop';
import SuccessSnackbar from '../../Snackbar/Success';
import Typography from '@material-ui/core/Typography';

interface IState{
  device:Device;
  dialog: {
    show: boolean;
    message: string;
  },

}

interface FormListProps  {
  device:Device | null;
  show?:boolean;
  update: (device: Device,customer:Customer) => void;
  customer:ICustomer | null | undefined;
  saved:boolean;

}

const FORMINPUT_NAME      = 'name';
const ERROR_FORM_MESSAGE  = 'Verifica i dati inseriti!';
const SAVED_MESSAGE       = 'Dati salvati correttamente!';


class DeviceForm extends React.Component<FormListProps,IState> {

  constructor(props:FormListProps){
    super(props);

    this.state = {
      device:new Device(),
      dialog: {
        show: false,
        message:'',

      }
    }

  }



  static getDerivedStateFromProps(nextProps:FormListProps, prevState:IState): IState {
    console.log('passa da qui????')
    let device = nextProps.device;
    if(device !== null && prevState.device.hash_id !== device.hash_id){
      let nextState = {} as IState;
      nextState.device = device;
      return nextState;
    }
    return prevState;
  }


  private handleChange = (e: React.ChangeEvent<HTMLInputElement>):void => {
    const { name, value } = e.currentTarget;
    let device : Device  = { ...this.state.device};
    switch (name) {
      case FORMINPUT_NAME:
      device.name = value;
      break;
    }
    this.setState({device: device}, () => console.log('aggiornato',this.state));
  };


  private handleSubmit = ():void => {
    if(this.state.device.name != null ){
      let newCustomer = Customer.init(this.props.customer!.hash_id);
      this.props.update(this.state.device,newCustomer);
    }else{
      let dialog      = this.state.dialog;
      dialog.show     = true;
      dialog.message  = ERROR_FORM_MESSAGE;
      this.setState({dialog: dialog});
    }


  }


  private handleCloseDialog = (): void => {
    let dialog      = this.state.dialog;
    dialog.show     = false;
    this.setState({dialog: dialog});
  }


  render(){
    console.log(this.state);
    let snackBar =  null;
    if(this.props.saved){
      snackBar = <SuccessSnackbar message={SAVED_MESSAGE} open={true} />
    }
    return(

      <Box className="marginTop-20">
        <AlertDialog show={this.state.dialog.show} message={this.state.dialog.message}  onClose={this.handleCloseDialog}/>
        <Typography variant="h6" >
          {this.state.device.slug}
        </Typography>
        <form autoComplete="off" className={this.props.show  ? `` : `hide`}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField fullWidth required id="standard-required" label="Nome" name={`${FORMINPUT_NAME}`} value={this.state.device.name?? ''} onChange={this.handleChange} />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" className="marginTop-20 right c-button" onClick={this.handleSubmit}>
            Salva
          </Button>
        </form>
        {snackBar}
      </Box>
    );
  }

}

function mapStateToProps(state: AppState) {
  return {
    customer: getCustomer(state),
    saved:getSaved(state)
  };
}


function mapDispatchToProps(dispatch: Dispatch<DeviceActions>) {
  return {
      update: (device: Device,customer: Customer) => update(dispatch,device,customer),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(DeviceForm);
