import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import ILayout,{Layout} from '../models/ILayout';
import {Pagination} from '../models/IPagination';


const itemsSelector = (state: AppState)    => state.layouts.items;

const paginateSelector = (state: AppState) => state.layouts.pagination;





export const getLayouts = createSelector(itemsSelector, (items: ILayout[]) => {
    return items;
});


export const getLayoutsPagination = createSelector(paginateSelector, (pagination: Pagination | null) => {
    return pagination;
});


export const getLandscapeLayouts = createSelector(itemsSelector, (items: ILayout[]) => {
    return items.filter((element, index, array) =>{
      return element.type === Layout.LANDSCAPE;
    });
});

export const getPortraitLayouts = createSelector(itemsSelector, (items: ILayout[]) => {
    return items.filter((element, index, array) =>{
      return element.type === Layout.PORTRAIT;
    });
});
