import {
  me as getMeFromApi
} from '../../services/Auth';
import { MeActionTypes } from '../constants/Auth';
import { Dispatch } from 'redux';
import IUser from '../../models/IUser';



interface FetchMe {
    type: MeActionTypes.FETCH_ME;
}

interface FetchMeSuccess {
    type: MeActionTypes.FETCH_ME_SUCCESS;
    payload: IUser;
}

interface FetchMeFail {
    type: MeActionTypes.FETCH_ME_FAIL;
    message: Error;
}





export async function getMe(dispatch: Dispatch<AuthActions>,access_token: string) {

    dispatch(onGetMeRequest());

    try {
     const response: IUser = await getMeFromApi(access_token);

      dispatch(getMeSuccess(response));
    } catch (err) {
      dispatch(getMeFail(err));
    }
}


function onGetMeRequest(): FetchMe {
  return {
  type: MeActionTypes.FETCH_ME
  };
}

function getMeSuccess(user: IUser): FetchMeSuccess {
  return {
    payload:user,
    type: MeActionTypes.FETCH_ME_SUCCESS
  };
}

function getMeFail(error: Error): FetchMeFail {
  return {
    type: MeActionTypes.FETCH_ME_FAIL,
    message:error
  };
}



export type AuthActions =
    | FetchMe
    | FetchMeSuccess
    | FetchMeFail;
