import React from 'react';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import { storeOnRedux,MediaActions } from '../store/actions/Media';
import { get } from '../store/actions/Media';
import { getMedia,getMediaPagination }  from '../selectors/Media';
import IMedia from '../models/IMedia';
import { Container }from '@material-ui/core/';
import { Pagination } from '../models/IPagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaCard from '../components/Media/Card';
import {
  Grid,
  Box
   } from '@material-ui/core/';
import MediaForm from '../components/Media/Form';
import CheckIcon from '@material-ui/icons/Check';

const BORDER_DIM: number = 2;
const ROW_PER_PAGE = 10;


interface MediaListProps  {
  media:IMedia[],
  pagination: Pagination | null,
  getMedia: (page: number | null, per_page: number | null) => void;
  storeOnRedux:(media: IMedia[]) => void;
  onMediaSelected?:(media: IMedia) => void;
}

interface IState {
  current_page:number;
  per_page:  number;
  prev_page: number | null;
  next_page: number | null;
  show_form: boolean;
  media:IMedia[],
  mediaSelected:IMedia[];

}


class Media extends React.Component<MediaListProps,IState> {

  constructor(props:MediaListProps){
    super(props);
    this.state={
      current_page:1,
      per_page: ROW_PER_PAGE ,
      prev_page: null,
      next_page: null,
      show_form: false,
      media:[],
      mediaSelected:[],

    }
    this.props.getMedia(null,ROW_PER_PAGE);


  }

  private nextMedia = ():void  => {
    this.setState({current_page:this.state.current_page +1},() => this.props.getMedia(this.state.current_page,ROW_PER_PAGE));
  }



  private handleClickMedia =(event: React.MouseEvent<HTMLElement>) =>{
    let hash_id = event.currentTarget.id;
    let currentMedia  = this.props.media.find(item => item.hash_id === hash_id)!;
    let mediaSelected = this.state.mediaSelected;
    if(mediaSelected.indexOf(currentMedia) > -1){
      let index =   mediaSelected.indexOf(currentMedia);
      mediaSelected.splice(index,1);
    }else{
      mediaSelected.push(currentMedia);
    }
    //this.setState({mediaSelected:mediaSelected},() => this.props.storeOnRedux(this.state.mediaSelected));
    this.setState({mediaSelected:mediaSelected});
    if(this.props.onMediaSelected !== undefined){
        this.props.onMediaSelected(currentMedia);
    }


  }




  render(){

    let items = this.props.media.map((row,index) =>{
    return    <Grid key={index}  item xs={12} md={3}>
                <Box  border={this.state.mediaSelected.includes(row) ? BORDER_DIM : 0}
                      id={row.hash_id}
                      className="selected-card-box"
                      onClick={this.handleClickMedia}>
                  <CheckIcon className={`${this.state.mediaSelected.includes(row) ? '' : 'hide'} checked-box`}/>
                  <MediaCard media={row}  />
                </Box>
              </Grid>

    });

    let content = <Grid container spacing={3}>
                  {items}
                 </Grid>;

    return(
      <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="baseline">
              <MediaForm />
            </Grid>
          <InfiniteScroll
            dataLength={this.props.media.length}
            next={this.nextMedia}
            hasMore={true}
            loader={<h4></h4>}
            className="hide-overflow marginTop-20"
            >
            {content}
          </InfiniteScroll>

      </Container>
    )
  }

}
function mapStateToProps(state: AppState) {
  return {
    media: getMedia(state),
    pagination: getMediaPagination(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<MediaActions>) {
  return {
      getMedia: (page: number | null,per_page: number | null) => get(dispatch,page,per_page),
      storeOnRedux: (media: IMedia[]) => storeOnRedux(dispatch,media),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Media);
