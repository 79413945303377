import { LayoutActions } from '../actions/Layout';
import { LayoutActionTypes } from '../constants/Layout';
import { Reducer } from 'redux';
import  ILayout  from '../../models/ILayout';
import { Pagination }  from '../../models/IPagination';

export interface LayoutSate {
  items: ILayout[];
  loading: boolean;
  error: Error | null;
  pagination: Pagination | null;
}

const initialState = {
  items: [],
  loading: false,
  error: null,
  pagination:null,
  saved:false,

};

export const layoutReducer: Reducer<LayoutSate, LayoutActions> = ( state = initialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.FETCH_LAYOUTS:
    return {
      ...state,
      loading: true
    };

    case LayoutActionTypes.FETCH_LAYOUTS_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case LayoutActionTypes.FETCH_LAYOUTS_SUCCESS:
    return {
      ...state,
      items: action.payload,
      loading: false,
      pagination:action.pagination
    };
    case LayoutActionTypes.FETCH_RESOURCE:
    return {
      ...state,
      loading:true,
    };
    case LayoutActionTypes.FETCH_RESOURCE_SUCCESS:
    return {
      ...state,
      loading:false,
    }
    case LayoutActionTypes.FETCH_RESOURCE_FAIL:
    return {
      ...state,
      loading:false,
      error:action.error
    }
    default:
    return state;
  }
};
