import IResponseBody from "./IResponseBody";

export default interface IPagination {
  readonly total: number;
  readonly count: number;
  readonly per_page: number;
  readonly current_page: number;
  readonly prev_page: number;
  readonly next_page: number;
  readonly total_pages: number;
  readonly next_page_url: string | null;
  readonly prev_page_url: string | null;

};



export class Pagination  {
  public total:  number;
  public size:  number;
  public per_page:  number;
  public current_page:  number;
  public prev_page:  number;
  public next_page: number;
  public total_pages: number;
  public next_page_url: string |null;
  public prev_page_url: string |null;


  constructor(data : IResponseBody){
    this.total        = data.total;
    this.size         = data.count;
    this.per_page     = data.per_page;
    this.current_page = data.current_page;
    this.prev_page    = data.prev_page;
    this.next_page    = data.next_page;
    this.total_pages  = data.total_pages;
    this.next_page_url= data.next_page_url;
    this.prev_page_url= data.prev_page_url;
  }



}
