import { DeviceActions } from '../actions/Device';
import { DeviceActionTypes } from '../constants/Device';
import { Reducer } from 'redux';
import  IDevice  from '../../models/IDevice';
import { Pagination }  from '../../models/IPagination';

export interface DeviceState {
  items: IDevice[];
  loading: boolean;
  error: Error | null;
  pagination: Pagination | null;
  saved:boolean;

}

const initialState = {
  items: [],
  loading: false,
  error: null,
  pagination:null,
  saved:false,

};

export const deviceReducer: Reducer<DeviceState, DeviceActions> = ( state = initialState, action) => {
  switch (action.type) {
    case DeviceActionTypes.FETCH_DEVICES:
    return {
      ...state,
      loading: true
    };

    case DeviceActionTypes.FETCH_DEVICES_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case DeviceActionTypes.FETCH_DEVICES_SUCCESS:
    return {
      ...state,
      items: action.payload,
      loading: false,
      pagination:action.pagination
    };
    case DeviceActionTypes.UPDATE_DEVICE:
    return {
      ...state,
      loading: true
    };

    case DeviceActionTypes.UPDATE_DEVICE_SUCCESS:
    return {
      ...state,
      loading: false,
      saved:true
    };
    case DeviceActionTypes.UPDATE_DEVICE_FAIL:
    return {
      ...state,
      error:action.error,
      saved:false
    };

    default:
    return state;
  }
};
