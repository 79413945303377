import React from 'react';
import Calendar  from '../components/Calendar/';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import { AdvertisingActions } from '../store/actions/Advertising';
import {  getAdvertisings } from '../selectors/Advertising';
import IAdvertising from '../models/IAdvertising';
import { getWithPlannings } from '../store/actions/Advertising';
import { EventInput } from '@fullcalendar/core'
import IFilters from '../models/IFilters';
import {
  Button,
  Box
} from '@material-ui/core';
import moment from 'moment';
import history from '../history';
//import { DEFAULT_DATE_FORMAT } from "../utils/Config";


const DEFAULT_DATE_FORMAT = "YYYY/MM/DD HH:mm";

interface IPlanningProps {
  advertisings:IAdvertising[];
  getWithPlanningAdvertsings:(page: number | null,per_page: number | null,filters: IFilters) => void;

}

interface IState {
  filters: IFilters;

}

class Plannings extends React.Component<IPlanningProps,IState> {
  constructor(props:IPlanningProps){
    super(props);
    const startOfMonth = moment().startOf('month').format(DEFAULT_DATE_FORMAT);
    const endOfMonth   = moment().endOf('month').format(DEFAULT_DATE_FORMAT);
    this.state = {
      filters:{
        from:   startOfMonth,
        to:     endOfMonth
      }
    }
    this.props.getWithPlanningAdvertsings(null,null,this.state.filters);
  }


  private handleNext = () => {
    let nextMonthStart    = moment(this.state.filters.from!,DEFAULT_DATE_FORMAT).add(1,'M');
    let nextMonthEnd      = moment(this.state.filters.to!,DEFAULT_DATE_FORMAT).add(1,'M').endOf('month');
    let filters  = this.state.filters;
    console.log(nextMonthStart);
      console.log(nextMonthEnd);
    filters.from = nextMonthStart.format(DEFAULT_DATE_FORMAT);
    filters.to   = nextMonthEnd.format(DEFAULT_DATE_FORMAT);
    this.setState({filters: filters},() => this.props.getWithPlanningAdvertsings(null,null,this.state.filters));

  };

  private handlePrev = () => {
    let nextMonthStart    = moment(this.state.filters.from!).subtract(1,'M');
    let nextMonthEnd      = moment(this.state.filters.to!).subtract(1,'M').endOf('month');
    let filters = this.state.filters;
    filters.from = nextMonthStart.format(DEFAULT_DATE_FORMAT);
    filters.to   = nextMonthEnd.format(DEFAULT_DATE_FORMAT);
    this.setState({filters: filters},() => this.props.getWithPlanningAdvertsings(null,null,this.state.filters));
  };

  private handleToday = () =>{

    let filters = this.state.filters;
    filters.from = moment().startOf('month').format(DEFAULT_DATE_FORMAT);
    filters.to   = moment().endOf('month').format(DEFAULT_DATE_FORMAT);
    this.setState({filters: filters},() => this.props.getWithPlanningAdvertsings(null,null,this.state.filters));
  }


  private handleNewAdvertising = (event:  React.MouseEvent<HTMLElement>) => {

    history.push('advertising/new');
  }

  public render(){


  let events =  this.props.advertisings.reduce((filtered: EventInput[], item) => {

      item.plannings!.map((planning) => {
         filtered.push(  { title: item.name, start: new Date(planning.start_at), end:new Date(planning.end_at)});
        });
      return filtered;
    }, []);


    return(
      <Box>
      <Button className="right"   variant="contained" color="primary" onClick={this.handleNewAdvertising}>Nuova pubblicità</Button>
        <Calendar calendarEvents={events}
                  onNextClick ={this.handleNext}
                  onPrevClick ={this.handlePrev}
                  onTodayClick={this.handleToday}/>

      </Box>

    );

  }


};

function mapStateToProps(state: AppState) {
  return {
    advertisings: getAdvertisings(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<AdvertisingActions>) {
  return {
    getWithPlanningAdvertsings: (page: number | null,per_page: number | null, filters: IFilters) => getWithPlannings(dispatch,page,per_page,filters),


  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Plannings);
