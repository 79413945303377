import { Model } from "../helpers/ModelHelper";
import { Customer } from "./ICustomer";

export default interface IShop {
  readonly  hash_id: string;
  readonly  name: string | null;
  readonly  address: string | null;

};


export class Shop implements IShop {

  public hash_id: string;
  public name:    string | null;
  public address: string | null;
  public static TYPE = 'shops';

  constructor(){

    this.hash_id    = '';
    this.name       = '';
    this.address    = '';

  }

  public setName?(name: string):void{
    this.name = name;
  }


  public setAddress?(name: string):void{
    this.name = name;
  }

  public getName?():string | null{
    return this.name;
  }


  public getAddress?():string | null{
    return this.address;
  }

  public  static bodyObject(shop: Shop,customer: Customer):object {
    return {
      data:{
        type:this.TYPE,
        hash_id:shop.hash_id.length > 0 ? shop.hash_id : null,
        attributes:{
          name:shop.name,
          address:shop.address,
        },
        relationships:{
          customer:{
            data:{
              type:Customer.TYPE,
              hash_id:customer.hash_id
            }
          }
        }
      },

    };
  }



}
