import { AppState } from '../store/IStoreState';
import { createSelector } from 'reselect';
import IUser from '../models/IUser';


const isAuthenticatedSelector = (state: AppState) => state.auth.isAuthenticated;

const customerSelector = (state: AppState)  => state.auth.user;



export const getAutheticated = createSelector(isAuthenticatedSelector, (isAuthenticated: boolean) => {
    return isAuthenticated;
});


export const getCustomer = createSelector(customerSelector, (user: IUser | null) => {
    return user?.attributes.customer;
});
