import { AdvertisingActions } from '../actions/Advertising';
import { AdvertisingActionTypes } from '../constants/Advertising';
import { Reducer } from 'redux';
import  IAdvertising  from '../../models/IAdvertising';
import { Pagination }  from '../../models/IPagination';

export interface AdvertisngState {
  items: IAdvertising[];
  loading: boolean;
  error: Error | null;
  pagination: Pagination | null;
  saved: boolean;
}

const initialState = {
  items: [],
  loading: false,
  error: null,
  pagination:null,
  saved:false,

};

export const advertisingReducer: Reducer<AdvertisngState, AdvertisingActions> = ( state = initialState, action) => {
  switch (action.type) {
    case AdvertisingActionTypes.FETCH_ADVERTISING:
    return {
      ...state,
      loading: true
    };

    case AdvertisingActionTypes.FETCH_ADVERTISING_FAIL:
    return {
      ...state,
      error:action.error,
      loading: false
    };

    case AdvertisingActionTypes.FETCH_ADVERTISING_SUCCESS:

    return {
      ...state,
      items: action.payload,
      loading: false,
      pagination:action.pagination
    };
    case AdvertisingActionTypes.STORE_ADVERTISING:
    return {
      ...state,
      loading:true,
    }
    case AdvertisingActionTypes.STORE_ADVERTISING_SUCCESS:
    return {
      ...state,
      saved:true,
      loading:false,
    }
    case AdvertisingActionTypes.STORE_ADVERTISING_FAIL:
    return {
      ...state,
      loading:false,
      error:action.error

    }
    default:
    return state;
  }
};
