import React from 'react';
import moment from 'moment';
import './style.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { DEFAULT_DATE_FORMAT } from "../../../utils/Config";




interface IDateRange {
  startDate:      moment.Moment;
  endDate:        moment.Moment;
  id:             number;
  onDeleteClik?:(id:number) => void;
}

class DateRangeItem extends React.Component<IDateRange> {
  constructor(props: IDateRange){
    super(props);

  }


  private handleDelete = (event: React.MouseEvent<HTMLElement>)  => {
    if(this.props.onDeleteClik !== undefined){
      this.props.onDeleteClik(this.props.id);
    }
  }

  render(){


    return(


    <Card>
      <IconButton className={`${this.props.onDeleteClik !== undefined ? `` : `hide`} right`} onClick={this.handleDelete}>
        <HighlightOffIcon  color="primary"/>
      </IconButton>
        <CardContent className="marginTop-5">
         <Typography>{this.props.startDate.format(DEFAULT_DATE_FORMAT)}   {this.props.endDate.format(DEFAULT_DATE_FORMAT)}</Typography>
        </CardContent>
    </Card>

    );
  }


}

export default DateRangeItem;
