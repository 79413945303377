/* eslint-disable */

import React from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventInput } from '@fullcalendar/core'
import IAdvertising from '../../models/IAdvertising';
import Button from '@material-ui/core/Button';



import './main.scss'



interface IPlanningProps {
  calendarWeekends?: boolean;
  calendarEvents?: EventInput[];
  onPrevClick:() => void;
  onNextClick:() => void;
  onTodayClick:()=> void;


}

interface IState {
  calendarWeekends:boolean;

}

class Calendar extends React.Component<IPlanningProps,IState> {
  calendarComponentRef = React.createRef<FullCalendar>()
  constructor(props:IPlanningProps){
    super(props);
    this.state = {
      calendarWeekends: true,

    }

  }

  private handleNext = () => {
    this.props.onNextClick();
    let calendarApi = this.calendarComponentRef.current!.getApi();
    calendarApi.next();
  }

  private handlePrev = () => {
    this.props.onPrevClick();
    let calendarApi = this.calendarComponentRef.current!.getApi();
    calendarApi.prev();
  }

  private handleToday =() =>{
    this.props.onTodayClick();
    let calendarApi = this.calendarComponentRef.current!.getApi();
    calendarApi.prev();
  }



  public render(){

    return(
      <FullCalendar
          defaultView="dayGridMonth"
          header={{
            left: 'CustomPrev CustomNext CustomToday',
            center: 'title',
            right: '',
            //right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          customButtons={{  CustomNext:   {text: 'avanti',click: this.handleNext },
                            CustomPrev:   {text: 'indietro',click: this.handlePrev },
                            CustomToday:  {text: 'oggi',click: this.handleToday }}}
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
          ref={ this.calendarComponentRef }
          weekends={ this.state.calendarWeekends }
          events={ this.props.calendarEvents }
          locale="it"
          buttonText={{
            today: 'oggi',
            month: 'mese',
            week:  'settimana',
            day:   'giorno',
            list:  'lista'

          }}


          />

    );

  }


};

export default Calendar;
