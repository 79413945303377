export default interface IPlanning {
  readonly hash_id:     string;
  readonly start_at:    Date;
  readonly end_at:      Date;

}


export class Planning  implements IPlanning  {
  public static readonly  TYPE = 'planning';

  public hash_id:   string;
  public start_at:  Date;
  public end_at:    Date;

  public formatted_start_at: string;
  public formatted_end_at: string;


  constructor(){

    this.hash_id    = '';
    this.start_at   = new Date();
    this.end_at     = new Date();
    this.formatted_start_at = '';
    this.formatted_end_at = '';


  }



}
