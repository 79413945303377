export enum MediaActionTypes {
    FETCH_MEDIA           = 'FETCH_MEDIA',
    FETCH_MEDIA_SUCCESS   = 'FETCH_MEDIA_SUCCESS',
    FETCH_MEDIA_FAIL      = 'FETCH_MEDIA_FAIL',

    STORE_MEDIA           = 'STORE_MEDIA',
    STORE_MEDIA_SUCCESS   = 'STORE_MEDIA_SUCCESS',
    STORE_MEDIA_FAIL      = 'STORE_MEDIA_FAIL',

    STORE_MEDIA_ON_REDUX  = 'STORE_MEDIA_ON_REDUX',

}
