import React from 'react';
import {Shop} from '../../../models/IShop';
import Grid from '@material-ui/core/Grid';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Box,
  TextField}from '@material-ui/core/';
import { AppState } from '../../../store/IStoreState';
import { store,update,ShopActions } from '../../../store/actions/Shop';
import  AlertDialog  from '../../../components/Dialogs/AlertDialog';
import { getCustomer } from '../../../selectors/Auth';
import ICustomer,{Customer} from '../../../models/ICustomer';
import { getSaved }  from '../../../selectors/Shop';
import SuccessSnackbar from '../../Snackbar/Success';

interface IState{
  new_shop:Shop;
  dialog: {
    show: boolean;
    message: string;
  },
  edit:boolean;

}

interface FormListProps  {
  shop?:Shop | null;
  show?:boolean;
  store: (shop: Shop,customer:Customer) => void;
  update: (shop: Shop,customer:Customer) => void;
  customer:ICustomer | null | undefined;
  saved:boolean;

}

const FORMINPUT_NAME      = 'name';
const FORMINPUT_ADDRESS   = 'address';
const ERROR_FORM_MESSAGE  = 'Verifica i dati inseriti!';
const SAVED_MESSAGE       = 'Dati salvati correttamente!';


class ShopForm extends React.Component<FormListProps,IState> {

  constructor(props:FormListProps){
    super(props);

    this.state = {
      new_shop:new Shop(),
      dialog: {
        show: false,
        message:'',

      },
      edit:false,
    }

  }



  static getDerivedStateFromProps(nextProps:FormListProps, prevState:IState): IState {

    let shop = nextProps.shop;
    if(shop !== null && shop !== undefined && prevState.new_shop.hash_id !== shop.hash_id){
      let nextState = {} as IState;
      nextState.new_shop = shop?? new Shop();
      nextState.edit     = shop !==null ? true : false;
      return nextState;
    }
    return prevState;
  }


  private handleChange = (e: React.ChangeEvent<HTMLInputElement>):void => {
    const { name, value } = e.currentTarget;
    let shop  = { ...this.state.new_shop};
    switch (name) {
      case FORMINPUT_NAME:
      shop.name = value;
      break;
      case FORMINPUT_ADDRESS:
      shop.address = value;
      break;
    }

    this.setState({new_shop: shop});
  };


  private handleSubmit = ():void => {
    if(this.state.new_shop.name != null ){
      let newCustomer = Customer.init(this.props.customer!.hash_id);
      if(this.state.edit){
        this.props.update(this.state.new_shop,newCustomer);
      }else{
        this.props.store(this.state.new_shop,newCustomer);
      }

      this.setState({new_shop:  new Shop(),edit:false});
    }else{
      let dialog      = this.state.dialog;
      dialog.show     = true;
      dialog.message  = ERROR_FORM_MESSAGE;
      this.setState({dialog: dialog});
    }


  }


  private handleCloseDialog = (): void => {
    let dialog      = this.state.dialog;
    dialog.show     = false;
    this.setState({dialog: dialog});
  }


  render(){
    let snackBar =  null;
    if(this.props.saved){
      snackBar = <SuccessSnackbar message={SAVED_MESSAGE} open={true} />
    }
    return(

      <Box className="marginTop-20">
        <AlertDialog show={this.state.dialog.show} message={this.state.dialog.message}  onClose={this.handleCloseDialog}/>
        <form autoComplete="off" className={this.props.show  ? `` : `hide`}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField fullWidth required id="standard-required" label="Nome" name={`${FORMINPUT_NAME}`} value={this.state.new_shop.name?? ''} onChange={this.handleChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField fullWidth required id="standard-required" label="Indirizzo" name={`${FORMINPUT_ADDRESS}`}  value={this.state.new_shop.address?? ''}  onChange={this.handleChange}  />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" className="marginTop-20 right c-button" onClick={this.handleSubmit}>
            Salva
          </Button>
        </form>
        {snackBar}
      </Box>
    );
  }

}

function mapStateToProps(state: AppState) {
  return {
    customer: getCustomer(state),
    saved:getSaved(state)
  };
}


function mapDispatchToProps(dispatch: Dispatch<ShopActions>) {
  return {
      store: (shop: Shop,customer: Customer)  => store(dispatch,shop,customer),
      update: (shop: Shop,customer: Customer) => update(dispatch,shop,customer),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(ShopForm);
