import React from 'react';
import { connect } from "react-redux";
import { AppState } from '../store/IStoreState';
import { Dispatch } from "redux";
import {AdvertisingActions} from '../store/actions/Advertising';
import  { get } from '../store/actions/Advertising';
import { getAdvertisings,getAdvertisingsPagination }  from '../selectors/Advertising';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IAdvertising,{Advertising} from '../models/IAdvertising';
import {
  Box,
  Container}from '@material-ui/core/';
import { TablePaginationActions } from '../components/Table/TablePaginationActions';
import { Pagination } from '../models/IPagination';
import EditIcon from '@material-ui/icons/Edit';


interface AdvertisingsListProps  {
  advertisings:IAdvertising[],
  pagination: Pagination | null,
  getAdvertisings: (page: number | null, per_page: number | null) => void;
}

interface IState {
  current_page:number;
  per_page:  number;
  prev_page: number | null;
  next_page: number | null;
  show_form: boolean;

}

const ROW_PER_PAGE = 10;

class Advertisings extends React.Component<AdvertisingsListProps,IState> {

  constructor(props:AdvertisingsListProps){
    super(props);
    this.state={
      //la prima pagina della libreria  è 0
      current_page:0,
      per_page: ROW_PER_PAGE ,
      prev_page: null,
      next_page: null,
      show_form: false,


    }
    this.props.getAdvertisings(null,ROW_PER_PAGE);


  }




  private handleChangePage = (event: unknown, newPage: number) => {
      this.setState({current_page:newPage}, () => this.props.getAdvertisings(this.state.current_page+1,this.state.per_page));
  };

  private handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({per_page: parseInt(event.currentTarget.value)},() =>   this.props.getAdvertisings(this.state.current_page+1,this.state.per_page));

  };



  render(){


    return(
      <Container maxWidth="lg">
        <Box className=" marginTop-20">
        <TableContainer component={Paper} className={`${this.props.advertisings.length  === 0 ? `hide` : `` } marginTop-20`}>
            <Table  aria-label="custom pagination table">
              <TableBody>
              {this.props.advertisings.map((adv) => (
                <TableRow key={adv.hash_id}>
                  <TableCell component="th" scope="row">
                  {adv.name}
                   </TableCell>
                   <TableCell component="th" scope="row">
                    <label
                    className={Advertising.labelState(adv.state)}
                    >{Advertising.stringState(adv.state)}</label>
                    </TableCell>
                    <TableCell component="th" scope="row">
                     {Advertising.isPlanned(adv)}
                     </TableCell>
                   <TableCell align="left"><EditIcon /></TableCell>
                </TableRow>

              ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    colSpan={3}
                    count={this.props.pagination !== null ? this.props.pagination.total : 0}
                    rowsPerPage={this.state.per_page}
                    page={this.state.current_page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          </Box>
        </Container>
    )
  }

}
function mapStateToProps(state: AppState) {
  return {
    advertisings: getAdvertisings(state),
    pagination: getAdvertisingsPagination(state),
  };
}


function mapDispatchToProps(dispatch: Dispatch<AdvertisingActions>) {
  return {
      getAdvertisings: (page: number | null,per_page: number | null) => get(dispatch,page,per_page),
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(Advertisings);
