import {
  get as getSubscriptionFromApi,
} from '../../services/Subscription';
import { SubscriptionActionTypes } from '../constants/Subscription';
import { Dispatch } from 'redux';
import ISingleResponseBody from '../../models/ISingleResponseBody';
import  ISubscription  from '../../models/ISubscription';



/****GET SUBSCRIPTION INFO***/

interface FetchSubscription {
    type: SubscriptionActionTypes.FETCH_SUBSCRIPTION;
}

interface FetchSubscriptionSuccess {
    type: SubscriptionActionTypes.FETCH_SUBSCRIPTION_SUCCESS;
    payload: ISubscription;
}

interface FetchSubscriptionFail {
    type: SubscriptionActionTypes.FETCH_SUBSCRIPTION_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<SubscriptionActions>,page: number | null,per_page: number | null) {
    dispatch(onGetSubscriptionRequest());

    try {
     const response: ISingleResponseBody = await getSubscriptionFromApi(page,per_page);

      dispatch(getSubscriptionSuccess(response));
    } catch (err) {
      dispatch(getSubscriptionFail(err));
    }
}


function onGetSubscriptionRequest(): FetchSubscription {
  return {
  type: SubscriptionActionTypes.FETCH_SUBSCRIPTION
  };
}

function getSubscriptionSuccess(body: ISingleResponseBody): FetchSubscriptionSuccess {
  return {
    payload:  <ISubscription> body.data.attributes,
    type: SubscriptionActionTypes.FETCH_SUBSCRIPTION_SUCCESS
  };
}

function getSubscriptionFail(error: Error): FetchSubscriptionFail {
  return {
    type: SubscriptionActionTypes.FETCH_SUBSCRIPTION_FAIL,
    error:error
  };
}





export type SubscriptionActions =
    | FetchSubscription
    | FetchSubscriptionSuccess
    | FetchSubscriptionFail
