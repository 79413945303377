import ISingleResponseBody from "./../models/ISingleResponseBody";
import ApiError from "./ApiError";
import {LOCAL_STORAGE_PREFIX, API_VERSION, API_ENDPOINT,TOKEN_TYPE } from "./../utils/Config";
import { createQueryString } from './utils';



export async function get(page:number | null,per_page: number | null): Promise<ISingleResponseBody> {


  const USER = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}user`);
  const TOKEN = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}access_token`);

  let requestHeaders: any = { 'Authorization': `${TOKEN_TYPE} ${TOKEN}` , 'User':  USER};

  var queryString = createQueryString(page,per_page);

  const requestUrl = `${API_ENDPOINT}${API_VERSION}/subscription/details${queryString}`;

  const requestInit: RequestInit = {
       headers:requestHeaders,
       method: "GET"
  };



  const response = await fetch(requestUrl, requestInit);

  if (response.ok) {
    return await response.json();
  } else {

    throw new ApiError(response.status, response.statusText);
  }
}
