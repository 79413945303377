import {
  get as getDevicesFromApi,
  update as updateDeviceFromApi
} from '../../services/Device';
import { DeviceActionTypes } from '../constants/Device';
import { Dispatch } from 'redux';
import IResponseBody from '../../models/IResponseBody';
import { Pagination } from '../../models/IPagination';
import  IDevice,{Device}  from '../../models/IDevice';
import  IShop,{ Shop }  from '../../models/IShop';
import { Customer }  from '../../models/ICustomer';


/****GET DEVICES***/

interface FetchDevices {
    type: DeviceActionTypes.FETCH_DEVICES;
}

interface FetchDevicesSuccess {
    type: DeviceActionTypes.FETCH_DEVICES_SUCCESS;
    payload: IDevice[];
    pagination: Pagination | null;
}

interface FetchDevicesFail {
    type: DeviceActionTypes.FETCH_DEVICES_FAIL;
    error: Error;
}



export async function get(dispatch: Dispatch<DeviceActions>,page: number | null,per_page: number | null) {
    dispatch(onGetDeviceRequest());

    try {
     const response: IResponseBody = await getDevicesFromApi(page,per_page);

      dispatch(getDevicesSuccess(response));
    } catch (err) {
      dispatch(getDevicesFail(err));
    }
}


function onGetDeviceRequest(): FetchDevices {
  return {
  type: DeviceActionTypes.FETCH_DEVICES
  };
}

function getDevicesSuccess(body: IResponseBody): FetchDevicesSuccess {
  let paginationProperties = new Pagination(body);
  let payload = mapResponseToDevices(body);
  return {
    payload:payload,
    pagination: paginationProperties,
    type: DeviceActionTypes.FETCH_DEVICES_SUCCESS
  };
}

function getDevicesFail(error: Error): FetchDevicesFail {
  return {
    type: DeviceActionTypes.FETCH_DEVICES_FAIL,
    error:error
  };
}


/****UPDATE DEVICE***/

interface UpdateDevice {
    type: DeviceActionTypes.UPDATE_DEVICE;
}

interface UpdateDeviceSuccess {
    type: DeviceActionTypes.UPDATE_DEVICE_SUCCESS;
}

interface UpdateDeviceFail {
    type: DeviceActionTypes.UPDATE_DEVICE_FAIL;
    error: Error;
}


export async function update(dispatch: Dispatch<DeviceActions>,device: Device,customer: Customer ){

  dispatch(onUpdateDevice());

  try {
    await updateDeviceFromApi(device,customer);
    dispatch(updateDeviceSuccess());
    get(dispatch,null,null);
  } catch (err) {
    dispatch(updateDeviceFail(err));
  }

}

function onUpdateDevice(): UpdateDevice {
  return {
  type: DeviceActionTypes.UPDATE_DEVICE
  };
}

function updateDeviceSuccess(): UpdateDeviceSuccess {
  console.log('aggiornamento riuscito');
  return {
    type: DeviceActionTypes.UPDATE_DEVICE_SUCCESS
  };
}

function updateDeviceFail(error: Error): UpdateDeviceFail {
  return {
    error: error,
    type: DeviceActionTypes.UPDATE_DEVICE_FAIL,
  };
}




function mapResponseToDevices(body: IResponseBody): IDevice[]{

  let shops =  body.included.reduce((filtered: IShop[], item) => {
    if (item.type === Shop.TYPE) {

       filtered.push(<IShop>item.attributes);
    }
    return filtered;
  }, []);


  let payload = body.data.map((item) => {
    let devices =   <IDevice> item.attributes;

    let relatedShop = item.relationships.data.find((item) =>{
      return  item.type  === Shop.TYPE

    } );

    if(relatedShop === undefined){
      return devices;
    }

    let relSHop = shops.filter((shop,index) => {
          if(relatedShop!.hash_id === shop.hash_id ){
            return shop;
          }
    });


    devices.shop = relSHop[0];

    return devices;
  });

  return payload;

}




export type DeviceActions =
    | FetchDevices
    | FetchDevicesSuccess
    | FetchDevicesFail
    | UpdateDevice
    | UpdateDeviceSuccess
    | UpdateDeviceFail
